export const signupValidations = {
    validations: null
}

export function signupValidationsActions(getStore, getActions, setStore) {
  return {
    validateSignupForm: (formData) => {
        const { first_name, last_name, email, phone, password } = formData;
        const errors = {};
  
          if (first_name.trim() === "") {
            errors.first_name = "Please enter your first name.";
          }
        
          if (last_name.trim() === "") {
            errors.last_name = "Please enter your last name.";
          }
        
          if (email.trim() === "") {
            errors.email = "Please enter your email address.";
          } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Please enter a valid email address.";
          }
        
          if (phone.trim() === "") {
            errors.phone = "Please enter your phone number.";
          }
        
          if (password.trim() === "") {
            errors.password = "Please enter your password.";
          }
      
        return { isValid: Object.keys(errors).length === 0, errors };
      },

      validateBusinessForm: (businessForm) => {
        const errors = {};
  
        // 1. Validate type
        if (!businessForm.type) {
            errors.type = "Please select a business type.";
        } else if (businessForm.type !== "LLC") {
            // 1.1 Validate fields for types other than LLC
            if (!businessForm.shares) {
            errors.shares = "Please enter the number of shares.";
            }
            if (!businessForm.shares_value) {
            errors.shares_value = "Please enter the shares value.";
            }
            if (!businessForm.president) {
            errors.president = "Please enter the president's name.";
            }
            if (!businessForm.secretary) {
            errors.secretary = "Please enter the secretary's name.";
            }
            if (!businessForm.treasurer) {
            errors.treasurer = "Please enter the treasurer's name.";
            }
        }

        // 2. Validate formation state
        if (!businessForm.formation_state) {
            errors.formation_state = "Please select a formation state.";
        }

        // 3. Validate name
        if (!businessForm.name) {
            errors.name = "Please enter a company name.";
        }

        // 4. Validate designator
        if (!businessForm.designator) {
            errors.designator = "Please select a designator.";
        }

        // 5. Validate activity
        if (!businessForm.activity) {
            errors.activity = "Please enter a business activity.";
        }

        // 6. Validate address fields if afthonia_address is false
        if (!businessForm.afthonia_address) {
            if (!businessForm.address) {
            errors.address = "Please enter an address.";
            }
            if (!businessForm.city) {
            errors.city = "Please enter a city.";
            }
            if (!businessForm.state) {
            errors.state = "Please select a state.";
            }
            if (!businessForm.zip) {
            errors.zip = "Please enter a ZIP code.";
            }
        }

        const isValid = Object.keys(errors).length === 0;

        return { isValid, errors };
      },

      // In your store or actions file
    validateSignupOwnersForm: (owners) => {
        const store = getStore();
        const actions = getActions();
    const errors = [];
  
    owners.forEach((owner, index) => {
      const ownerErrors = {};
  
      // 2. Validate owner data based on individual or company selection
      if (owner.owner_type === "individual") {
        if (!owner.first_name) {
          ownerErrors.first_name = `Please enter the first name of Owner ${index + 1}.`;
        }
        if (!owner.last_name) {
          ownerErrors.last_name = `Please enter the last name of Owner ${index + 1}.`;
        }
      } else if (owner.owner_type === "company") {
        if (!owner.company_name) {
          ownerErrors.company_name = `Please enter the company name of Owner ${index + 1}.`;
        }
      }
  
      // 3. Validate address fields if isChecked is false
      if (!owner.afthonia_address) {
        if (!owner.address) {
          ownerErrors.address = `Please enter the address of Owner ${index + 1}.`;
        }
        if (!owner.city) {
          ownerErrors.city = `Please enter the city of Owner ${index + 1}.`;
        }
        if (!owner.state) {
          ownerErrors.state = `Please select the state of Owner ${index + 1}.`;
        }
        if (!owner.zip) {
          ownerErrors.zip = `Please enter the ZIP code of Owner ${index + 1}.`;
        }
      }
  
      // 4. Validate shares_owned or percentage_ownership based on businessType
      const businessType = store.signupForm.company.type;
      if (businessType === "LLC") {
        if (!owner.percentage_ownership) {
          ownerErrors.percentage_ownership = `Please enter the percentage of ownership of Owner ${index + 1}.`;
        }
      } else {
        if (!owner.shares_owned) {
          ownerErrors.shares_owned = `Please enter the number of shares owned by Owner ${index + 1}.`;
        }
      }
  
      errors.push(ownerErrors);
    });
  
    // Check if there are any errors
    const isValid = errors.every((ownerErrors) => Object.keys(ownerErrors).length === 0);
  
    return { isValid, errors };
  },

  validateNewSignupOwnersForm: (owners) => {
    const store = getStore();
    const actions = getActions();
  const errors = [];

owners.forEach((owner, index) => {
  const ownerErrors = {};

  // 2. Validate owner data based on individual or company selection
  if (owner.owner_type === "individual") {
    if (!owner.first_name) {
      ownerErrors.first_name = `Please enter the first name of Owner ${index + 1}.`;
    }
    if (!owner.last_name) {
      ownerErrors.last_name = `Please enter the last name of Owner ${index + 1}.`;
    }
  } else if (owner.owner_type === "company") {
    if (!owner.company_name) {
      ownerErrors.company_name = `Please enter the company name of Owner ${index + 1}.`;
    }
  }

  // 3. Validate address fields if isChecked is false
  if (!owner.afthonia_address) {
    if (!owner.address) {
      ownerErrors.address = `Please enter the address of Owner ${index + 1}.`;
    }
    if (!owner.city) {
      ownerErrors.city = `Please enter the city of Owner ${index + 1}.`;
    }
    if (!owner.state) {
      ownerErrors.state = `Please select the state of Owner ${index + 1}.`;
    }
    if (!owner.zip) {
      ownerErrors.zip = `Please enter the ZIP code of Owner ${index + 1}.`;
    }
  }

  // 4. Validate shares_owned or percentage_ownership based on businessType
  const businessType = store.newCompanyForm.company.type;
  if (businessType === "LLC") {
    if (!owner.percentage_ownership) {
      ownerErrors.percentage_ownership = `Please enter the percentage of ownership of Owner ${index + 1}.`;
    }
  } else {
    if (!owner.shares_owned) {
      ownerErrors.shares_owned = `Please enter the number of shares owned by Owner ${index + 1}.`;
    }
  }

  errors.push(ownerErrors);
});

// Check if there are any errors
const isValid = errors.every((ownerErrors) => Object.keys(ownerErrors).length === 0);

return { isValid, errors };
},

  validateRegAgent: (registeredAgentForm) => {
    const errors = {};

    if (!registeredAgentForm.afthonia_address) {
      if (registeredAgentForm.agent_type === "individual") {
        if (!registeredAgentForm.first_name) {
          errors.first_name = "Please enter the first name.";
        }
        if (!registeredAgentForm.last_name) {
          errors.last_name = "Please enter the last name.";
        }
      } else if (registeredAgentForm.agent_type === "company") {
        if (!registeredAgentForm.company_name) {
          errors.company_name = "Please enter the company name.";
        }
      }
      if (!registeredAgentForm.afthonia_address) {
        if (!registeredAgentForm.email) {
            errors.email = "Please enter the email.";
        }
        if (!registeredAgentForm.phone) {
            errors.phone = "Please enter the phone number.";
        }
        if (!registeredAgentForm.address) {
            errors.address = "Please enter the address.";
        }
        if (!registeredAgentForm.city) {
            errors.city = "Please enter the city.";
        }
        if (!registeredAgentForm.zip) {
            errors.zip = "Please enter the ZIP code.";
        }
    }
    }

    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
  },

  validateEin: (einForm) => {
    const errors = {};

    if (!einForm.own_application) {
        if (!einForm.first_name.trim()) {
          errors.firstName = "First name is required.";
        }
    
        if (!einForm.last_name.trim()) {
          errors.lastName = "Last name is required.";
        }
    
        if (!einForm.foreign) {
          if (!einForm.tax_type) {
            errors.taxType = "Please select a tax type (SSN or ITIN).";
          }
    
          if (!einForm.tax_number.trim()) {
            errors.taxNumber = "Tax number is required.";
          }
        }
      }
  
    return { isValid: Object.keys(errors).length === 0, errors };
  },
  
  }
}
