import React from "react";
import '../../styles/services-details.css';
// import Hr from "../../img/hr.jpg";

function userHR() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>HR and Payroll Management</h1>
            </div>
            <div className="service-content">
                <img src={Hr} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        Effective human resources and payroll management are the backbones of any thriving business. Afthonia's HR and Payroll Management services offer a comprehensive suite to ensure your LLC's internal operations are as smooth and efficient as your external offerings.</p>
                    <p>
                        <strong>Key aspects of our HR and Payroll services:</strong>
                        <br />
                        <br /><strong>1.-Talent Acquisition and Retention:</strong>We assist in developing strategies to attract top talent to your LLC and implement retention programs to keep your team motivated and committed.

                        <br /><strong>2.-Payroll Processing:</strong>Our streamlined payroll services ensure accurate and timely compensation for your employees, complete with tax withholdings, benefits administration, and compliance with employment laws.

                        <br /><strong>3.-Employee Relations Management:</strong>We provide support for maintaining healthy employee relations within your organization, including conflict resolution and the fostering of a positive work environment.

                        <br /><strong>4.-Regulatory Compliance:</strong>Navigating the complex web of employment law is a challenge we tackle head-on, keeping your business compliant with all federal, state, and local regulations.

                        <br /><strong>5.-Training and Development:</strong>Invest in your team's growth through our training and development programs, tailored to enhance their skills and advance their careers within your company.

                        <br /><strong>6.-Benefits Administration:</strong>Design and manage an attractive benefits package for your employees, including health insurance, retirement plans, and wellness programs, all handled by our expert team.

                        <br /><strong>7.-Performance Management Systems:</strong>Implement effective performance evaluation systems that align with your business goals, driving productivity and providing clear metrics for employee appraisals.

                        Partnering with Afthonia for your HR and Payroll needs means you're free to focus on the core aspects of your business, secure in the knowledge that your team is well-cared for and your company's HR practices are beyond reproach.

                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default userHR;