import React, { useContext, useState, useEffect } from "react";
import { Context } from "../store/appContext";
import { Link } from "react-router-dom";
import "../../styles/home.css";
import docsImage from "../../images/docs.jpeg"

export const MyCompany = () => {
    const { store, actions } = useContext(Context);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [myCompanies, setMyCompanies] = useState(store.userData.companies)
    const [companyDocs, setCompanyDocs] = useState([])
    const [loadingIds, setLoadingIds] = useState({});

    const getCompanyDocs = async (companyId) => {
        let { responseJson, response } = await actions.useFetch(`/documents/${companyId}`)
        if (response.ok) {
            setCompanyDocs(responseJson)
        } else {
            console.log("Failed getting company documents")
        }
    }

    const openPDF = async (filePath, id) => {
        setLoadingIds(prevState => ({ ...prevState, [id]: true }));
        const body = { filepath: filePath };

        try {
            await actions.downloadUserDocument("/download", body, "POST");
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingIds(prevState => ({ ...prevState, [id]: false }));
        }
    };

    useEffect(() => {
        setMyCompanies(store.userData.companies)
    }, [store.userData.companies]);

    useEffect(() => {
        if (selectedCompany) {
            getCompanyDocs(selectedCompany);
        }
    }, [selectedCompany]);

    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">

                <div className="row">
                    <h3 className="mt-4">My Documents</h3>
                    <span className="medium">Here you will find all your company's documents</span>
                </div>
                <Link to="/newcompany-business"><button type="button" className="btn btn-primary">Add New Company</button></Link>
                <div className="col-lg-4 mt-3">
                    <select id="document-type-select" className="form-select" aria-label="Default select example" onChange={(e) => setSelectedCompany(e.target.value)}>
                        <option value="">Select your company to view its documents...</option>
                        {myCompanies && myCompanies.length > 0 && (
                            myCompanies.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))
                        )}
                    </select>
                </div>
                <div className="row g-3 g-lg-4 pb-4 mt-3">
                    {companyDocs && companyDocs.length > 0 ? (
                        companyDocs.map((item, index) => (
                            <div key={index} className="col-lg-6 col-xxl-4">
                                <div className="board-card rounded">
                                    <span className="primary-tag">{item.category}</span>
                                    <div className="d-flex justify-content-center mt-4">
                                        <img src={docsImage} alt="" style={{ "maxWidth": "150px" }} />

                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <h4 className="mt-3">{item.name}</h4>
                                    </div>
                                    <p>
                                        {item.description}
                                    </p>
                                    <div
                                        className="d-flex justify-content-between align-items-center gap-1 mt-3 flex-wrap w-100"
                                    >
                                        <button type="button" className="w-100 btn btn-lg btn-outline-primary mt-3" onClick={() => openPDF(item.file_path, item.id)} disabled={loadingIds[item.id]}>
                                            {loadingIds[item.id] ? (
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            ) : (
                                                "View Document"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <h6>No Documents to show</h6>
                    )
                    }
                </div>
            </div>
        </>
    );
};

export default MyCompany;