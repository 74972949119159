import React from "react";
import '../../styles/services-details.css';
import Legal from "../../img/legal.jpg";

function userLegal() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>Legal Document Templates</h1>
            </div>
            <div className="service-content">
                <img src={Legal} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        Navigating the legal intricacies of forming and managing a Limited Liability Company (LLC) can be a daunting task for entrepreneurs. Afthonia recognizes this challenge and offers an empowering solution: a comprehensive suite of Legal Document Templates tailored specifically for LLCs.

                        Our collection encompasses a wide array of essential documents, meticulously crafted to meet the diverse legal requirements of LLC formation and operation. From foundational Operating Agreements to intricate Employment Contracts, each template is designed with precision and attention to detail.</p>

                    <p>
                        <strong>Key Benefits:</strong>
                        <br />
                        <br /><strong>1.-Ease of Use:</strong> Our templates are crafted for simplicity and ease of understanding, ensuring that you can modify and implement them without needing extensive legal knowledge.

                        <br /><strong>2.-Cost-Effective:</strong> Reduce legal expenses significantly. Our templates offer a reliable alternative to costly legal services, providing a cost-effective solution for your LLC's documentation needs.

                        <br /><strong>3.-Customizable and Flexible:</strong> Tailored to be adaptable, our templates can be easily modified to fit the unique aspects of your business, providing a personalized legal framework that grows with your company.

                        <br /><strong>4.-Compliance Focused:</strong> Stay aligned with legal standards. Each template is updated to reflect the latest legal requirements, giving you peace of mind and ensuring compliance with state and federal regulations.

                        <br /><strong>5.-Wide Range of Documents:</strong> Whether it's drafting your initial LLC agreement, managing contracts with vendors, or setting up employment policies, our library has you covered.

                        By leveraging Afthonia's Legal Document Templates, you gain access to a resource that not only simplifies the process of legal documentation but also empowers you to lay a strong, compliant foundation for your business. Embrace the ease and confidence that comes with having professionally crafted legal documents at your fingertips.

                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default userLegal;