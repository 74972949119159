import React from "react";
import '../../styles/services-details.css';
// import Ecommerce from "../../img/ecommerce.jpg";

function userEcommerce() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>E-Commerce and Website Integration Services</h1>
            </div>
            <div className="service-content">
                <img src={Ecommerce} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        In the digital age, a robust online presence is vital for business success. Afthonia's E-Commerce and Website Integration services equip your LLC with the tools and platforms necessary to excel in the online marketplace.</p>
                    <p>
                        <strong>Elevate your online business with our services:</strong>
                        <br />
                        <br /><strong>1.-Custom E-Commerce Solutions: </strong>We create bespoke e-commerce platforms that reflect your brand's ethos and meet your specific business needs, ensuring a seamless and intuitive shopping experience for your customers.

                        <br /><strong>2.-Website Integration:</strong>Integrate your website with essential business systems such as inventory management, accounting, and CRM to streamline operations and provide a unified user experience.

                        <br /><strong>3.-Payment Gateway Setup:</strong>Securely accept payments with a variety of payment gateways, offering flexibility and convenience to your customers while ensuring transactions are safe and reliable.

                        <br /><strong>4.-Mobile Optimization:</strong>With mobile commerce on the rise, we ensure that your e-commerce platform is fully optimized for mobile devices, providing a responsive and engaging experience for users on the go.

                        <br /><strong>5.-SEO and Analytics:</strong>Enhance your online visibility with our SEO strategies, and gain actionable insights into customer behavior with advanced analytics integration.

                        <br /><strong>6.-Support and Maintenance:</strong>Receive ongoing support and maintenance to ensure your e-commerce site remains up-to-date, secure, and efficient, adapting to the ever-evolving digital landscape.

                        With Afthonia’s E-Commerce and Website Integration services, your LLC will not only capture the growing online market but also deliver a shopping experience that fosters loyalty and drives sales. Let us help you build an e-commerce presence that stands out and scales with your business aspirations.

                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default userEcommerce;