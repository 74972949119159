import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Customer from "../../img/customer.jpg";
// import Legal from "../../img/legal.jpg";
// import Tax from "../../img/tax.jpg";
// import Trademark from "../../img/trademark.jpg";
// import Network from "../../img/network.jpg";
// import Ecommerce from "../../img/ecommerce.jpg";
// import Business from "../../img/business.jpg";
// import Hr from "../../img/hr.jpg";
import adminServices from "../../styles/adminServices.css";


function UserServices() {
    const [displayCurrentServices, setDisplayCurrentServices] = useState(true);
    const [displayFutureServices, setDisplayFutureServices] = useState(false);

    const handleCurrentClick = () => {
        setDisplayCurrentServices(true);
        setDisplayFutureServices(false);
    };

    const handleFutureClick = () => {
        setDisplayCurrentServices(false);
        setDisplayFutureServices(true);
    };

    return (
        <>
            <div className="col-md-6 srvcs-title">
                <h3>Services</h3>
                <span className="medium">Clikc the buttons to check our services.</span>
            </div>
            <div className="srvc-buttons">
                <button
                    onClick={handleCurrentClick}
                    className="btn btn-transparent srvc-buttons"
                >
                    <h6 style={{ color: '#8da9ee' }}>1 <i className="fa-solid fa-chevron-left" style={{ color: '#8da9ee' }}></i></h6>
                </button>
                <button
                    onClick={handleFutureClick}
                    className="btn btn-transparent srvc-buttons"
                >
                    <h6 style={{ color: '#8da9ee' }}><i className="fa-solid fa-chevron-right" style={{ color: '#8da9ee' }}></i> 2</h6>
                </button>
            </div>

            {displayCurrentServices && (
                <div className="srvcs-container">
                    <div className="row">
                        <div className="col-md-4">
                            <Link to="/user/legal-doc-templates" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                                <img src={Legal} className="card-img-top cards-img" alt="app-processing" />
                                <div className="card-body">
                                    <h5 className="card-title-srvcs">Legal Document Templates</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user/accounting-tax-services" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                                <img src={Tax} className="card-img-top cards-img" alt="compliance-monitoring" />
                                <div className="card-body">
                                    <h5 className="card-title-srvcs">Accounting and Tax Services</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user/customer-support" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                                <img src={Customer} className="card-img-top cards-img" alt="customer-support" />
                                <div className="card-body">
                                    <h5 className="card-title-srvcs">Customer Support Management</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Link to="/user/trademark-intellectual-property" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                                <img src={Trademark} className="card-img-top cards-img" alt="financial-overview" />
                                <div className="card-body">
                                    <h5 className="card-title-srvcs">Trademark and Intellectual Property</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user/networking-community-access" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                                <img src={Network} className="card-img-top cards-img" alt="document-management" />
                                <div className="card-body">
                                    <h5 className="card-title-srvcs">Networking and Community Access</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="/user/ecommerce-web-integration" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                                <img src={Ecommerce} className="card-img-top cards-img" alt="legal-consultation-coordination" />
                                <div className="card-body">
                                    <h5 className="card-title-srvcs">E-commerce and Website Integration</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {displayFutureServices && (
                <><div className="row">
                    <div className="col-md-4">
                        <Link to="/user/business-licensing-permits" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                            <img src={Business} className="card-img-top cards-img" alt="app-processing" />
                            <div className="card-body">
                                <h5 className="card-title-srvcs">Business Licensing and Permits</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/user/hr-payroll-management" className="btn btn-transparent buttons-user-services" style={{ background: 'white' }}>
                            <img src={Hr} className="card-img-top cards-img" alt="compliance-monitoring" />
                            <div className="card-body">
                                <h5 className="card-title-srvcs">HR and Payroll Management</h5>
                            </div>
                        </Link>
                    </div>
                </div></>

            )}
        </>
    );
}
export default UserServices;
