import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/home.css";

export const AdminUnpaidUsers = () => {
    const { store, actions } = useContext(Context);
    const [unpaidUsers, setUnpaidUsers] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchTerm, setSearchTerm] = useState("");

    const getUnpaidUsers = async () => {
        const { responseJson, response } = await actions.getUnpaidUsers();
        if (response.ok) {
            setUnpaidUsers(responseJson);
        } else {
            console.error("Error fetching users:", response.statusText);
        }
    };

    const handleSearchChange = (event) => {
        let newSearchTerm = event.target.value;
        setSearchTerm(event.target.value);

        if (newSearchTerm.trim() === '') {
            getUnpaidUsers()
        }
    };

    const handleSearchIconClick = () => {
        setShowSearch(!showSearch);
        if (!showSearch) {
            setTimeout(() => {
                document.querySelector('.search-input').focus();
            }, 500);
        }
    };

    const handleSearchSubmit = async () => {
        if (searchTerm.trim()) {
            await actions.searchUnpaid(searchTerm);
            setUnpaidUsers(store.filteredUnpaidUsers);
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleSort = (field) => {

        if (sortField === field) {
            // Toggle sort direction for other fields
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }

        // Sort the data for other fields
        sortData(field);
    };

    const sortData = (field) => {
        const sortedTasks = [...unpaidUsers];
        sortedTasks.sort((a, b) => {
            let valA, valB;

            switch (field) {
                case 'user_fullname':
                    valA = a.user_fullname.toLowerCase();
                    valB = b.user_fullname.toLowerCase();
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);

                case 'name':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'formation_state':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'registration_date':
                    valA = new Date(a.registration_date);
                    valB = new Date(b.registration_date);
                    break;
                default:
                    return 0;
            }

            // For numerical and predefined order sorting
            return (valA < valB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });

        setUnpaidUsers(sortedTasks);
    };

    useEffect(() => {
        getUnpaidUsers();
    }, []);

    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">
                <div className="row justify-content-between align-items-center mt-4">
                    <div className="col-md-6">
                        <h3>Unpaid Users</h3>
                        <span className="medium">This is the list of unpaid users.</span>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <input
                            className={`form-control search-input ${showSearch ? 'open' : ''}`}
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                        />
                        <button className="btn btn-transparent" type="button" onClick={handleSearchIconClick} style={{ backgroundColor: '#5075d0', color: 'white' }}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                </div>
                <div className="row my-2 g-3 g-lg-4 contact-section">
                    <div className="col">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                                tabIndex="0"
                            >
                                <div className="row my-2 g-3 g-lg-3">
                                    <div className="col">
                                        <div className="product-table p-3 px-md-4">
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 bg-white">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => getUnpaidUsers()}>#</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('user_fullname')}>User</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('name')}>Company</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('registration_date')}>Registration Date</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {unpaidUsers && unpaidUsers.length > 0 ? (
                                                            unpaidUsers.map((user, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{user.user_fullname}</td>
                                                                    <td>{user.name} {user.designator}</td>
                                                                    <td>{user.registration_date}</td>
                                                                    <td>
                                                                        <span className="badge bg-warning rounded-pill d-inline">
                                                                            Unpaid
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {/* Link to user details or payment page */}
                                                                        <Link to={`/admin/unpaid-users/${user.id}`}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-link btn-sm rounded-pill"
                                                                            >
                                                                                View Details
                                                                            </button>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No Unpaid Users</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminUnpaidUsers;
