import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/home.css";

export const AdminPurchases = () => {
    const { store, actions } = useContext(Context);
    const [purchasedServices, setPurchasedServices] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const getServices = async () => {
        let { responseJson, response } = await actions.useFetch("/purchases");
        if (response.ok) {
            setPurchasedServices(responseJson);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value.trim() === '') {
            getServices();
        }
    };

    const handleSearchIconClick = () => {
        setShowSearch(!showSearch);
        if (!showSearch) {
            setTimeout(() => {
                document.querySelector('.search-input').focus();
            }, 500);
        }
    };

    const handleSearchSubmit = async () => {
        if (searchTerm.trim()) {
            await actions.searchPurchasedServices(searchTerm);
            setPurchasedServices(store.searchResults);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleSort = (field) => {

        if (sortField === field) {
            // Toggle sort direction for other fields
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }

        // Sort the data for other fields
        sortData(field);
    };

    const sortData = (field) => {
        const sortedTasks = [...purchasedServices];
        sortedTasks.sort((a, b) => {
            let valA, valB;

            switch (field) {
                case 'service':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'company_name':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'is_paid':
                    valA = a[field] ? 1 : 0;
                    valB = b[field] ? 1 : 0;
                    break;
                case 'purchase_date':
                    valA = new Date(a.purchase_date);
                    valB = new Date(b.purchase_date);
                    break;
                case 'end_date':
                    valA = new Date(a.end_date);
                    valB = new Date(b.end_date);
                    break;
                default:
                    return 0;
            }

            // For numerical and predefined order sorting
            return (valA < valB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });

        setPurchasedServices(sortedTasks);
    };

    useEffect(() => {
        getServices();
    }, []);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">
            <div className="row justify-content-between align-items-center mt-4">
                <div className="col-md-6">
                    <h3>Purchased Services</h3>
                    <span className="medium">List of all purchased services.</span>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <input
                        className={`form-control search-input ${showSearch ? 'open' : ''}`}
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button className="btn btn-transparent" type="button" onClick={handleSearchIconClick} style={{ backgroundColor: '#5075d0', color: 'white' }}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                </div>
            </div>
            <div className="row my-2 g-3 g-lg-4 contact-section">
                <div className="col">
                    <div className="product-table p-3 px-md-4">
                        <div className="table-responsive">
                            <table className="table align-middle mb-0 bg-white">
                                <thead className="bg-light">
                                    <tr>
                                        <th style={{ cursor: 'pointer' }} onClick={() => getServices()}>#</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('service')}>Service Name</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('company_name')}>Company</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('is_paid')}>Status</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('purchase_date')}>Purchase Date</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('end_date')}>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchasedServices && purchasedServices.length > 0 ? (
                                        purchasedServices.map((service, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{service.service}</td>
                                                <td>{service.company_name}</td>
                                                <td>
                                                    <span className={`badge ${service.is_paid == true ? 'bg-success' : 'bg-warning'} rounded-pill`}>
                                                        {service.is_paid == true ? 'paid' : 'pending'}
                                                    </span>
                                                </td>
                                                <td>{service.purchase_date}</td>
                                                <td>{service.end_date}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No Purchased Services</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPurchases;
