import React, { useContext, useEffect } from "react";
import { Context } from "../store/appContext";
import rigoImageUrl from "../../img/rigo-baby.jpg";
import "../../styles/home.css";
import Uploader from "../component/uploader/uploader.jsx";
import NamesGenerator from "./namesGenerator.jsx";

export const Home = () => {
  const { store, actions } = useContext(Context);

  return (
    <div className="text-center mt-5">
      <h1>Hello Rigo!!</h1>
      <p>
        <img src={rigoImageUrl} />
      </p>
      <div className="alert alert-info">
        {store.message ||
          "Loading message from the backend (make sure your python backend is running)..."}
      </div>
      <p>
        This boilerplate comes with lots of documentation:{" "}
        <a href="https://start.4geeksacademy.com/starters/react-flask">
          Read documentation
        </a>
      </p>
      <div>
        <NamesGenerator />
      </div>
      <div>
        <p>Aquí sección de subir imagenes</p>
        <Uploader />
      </div>
      <div className="card"  style={{width:"400px"}}>
  <div className="card-header">
    <h5 className="card-title">Afthonia Help</h5>
  </div>
  <div className="card m-2 bg-secondary bg-opacity-75" style={{width:"300px"}}>
  <div className="card-body">
    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
  </div>
  </div>
  <div className="d-flex justify-content-end">
  <div className="card m-2 bg-primary bg-opacity-75" style={{width:"300px"}}>
  <div className="card-body">
    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
  </div>
  </div>
  </div>
  <div className="card-footer d-flex">
    <input className="form-control" type="text" placeholder="Your message..."></input>
    <button type="button" className="btn btn-primary ms-2">Send</button>
  </div>
  </div>
  <iframe src="https://iframe.dev.name.com/domain/search?partner_id=demo&key=b6bb87d41edca7eae5a2dbdc1c89bd82b7b25711"></iframe>
    </div>
  );
};
