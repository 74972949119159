

export const faqStore = {
  faqDashboard:[
    {
    question: "How long does it take to receive my completed LLC or corporation documents?",
    answer: "Depending on the State you chose, it can take 1 hour or couple of business days. We file the documents to the Secretary of State the same day you complete your order. Each Secretary of State has its own processing times."
  },
  {
    question: "How long does it take to get my EIN number?",
    answer: "If you have SSN we will apply and get your EIN number the same day your company is formed. If you don't have SSN, it can take a minimum of 4 business days."
  },
  {
    question: "What is an Operating Agreement, and why is it important?",
    answer: "It is a legal document that outlines the internal operations, ownership structure, and management of an LLC. It is important because it clarifies ownership, establishes procedures, protects limited liability status, and provides flexibility for the LLC's operations."
  },
  {
    question: "Can I make changes to my LLC or corporation after it has been formed?",
    answer: "Yes, you can make changes to your company after it has been formed. It is a process that has to be done with the Secretary of State and also an ammendment to the Operating Agreement."
  },
  {
    question: "What is the role of a registered agent?",
    answer: "A registered agent is an individual or entity designated to receive legal and official documents on behalf of a business entity, such as an LLC or corporation. They act as a point of contact between the business and the state or jurisdiction in which it is formed, helping to ensure legal compliance and facilitating effective communication."
  },
  {
    question: "What happens if I have further questions or need assistance after the formation process?",
    answer: "No worries! We are here to help. Feel free to reach our support channel anytime."
  },
]
}
