import { loadStripe } from "@stripe/stripe-js";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
const backendUrl = process.env.BACKEND_URL;
const stripePk = process.env.STRIPE_PK;

export const userStore = {
  userLogin: false,
  userData: { role: "admin" },
  userDocs: null,
  companyDocs: null,
};

export function userActions(getStore, getActions, setStore) {
  return {
    login: async (emailOrUsername, password) => {
      const store = getStore();
      let actions = getActions();
      let obj = {
        email_or_username: emailOrUsername,
        password: password,
      };

      let { responseJson, response } = await actions.useFetch(
        "/login",
        obj,
        "POST"
      );
      if (response.ok) {
        localStorage.setItem("token", responseJson.token);
        sessionStorage.setItem("token", responseJson.token);
        let token = localStorage.getItem("token");
        setStore({ ...store, userLogin: true });
        await actions.getUserData();
        await actions.getUserDocs();
      } else {
        console.log("login fallido");
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
        setStore({ ...store, userLogin: false });
      }

      return { responseJson, response };
    },

    logout: async () => {
      const store = getStore();
      const actions = getActions();
      let body = "";
      let { responseJson, response } = await actions.useFetch(
        "/logout",
        body,
        "POST"
      );
      if (response.ok) {
        localStorage.setItem("token", "");
        sessionStorage.setItem("token", "");
        setStore({ ...store, userLogin: false });
        setStore({ ...store, userData: {} });
      }
      return { responseJson, response };
    },

    retryPayment: async (body) => {
      const store = getStore();
      const actions = getActions();
      try {
        const response = await fetch(
          `${backendUrl}/api/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );

        if (response.ok) {
          const { id } = await response.json();

          localStorage.setItem("sessionId", JSON.stringify(id));

          const stripe = await loadStripe(stripePk);

          const { error } = await stripe.redirectToCheckout({ sessionId: id });

          if (error) {
            console.log(error);
            Swal.fire({
              title: "Sorry!",
              text: `Payment failed: ${error.message}`,
              icon: "error",
              confirmButtonColor: "#4874d7",
            });
          }
        } else {
          throw new Error(`API request failed: ${response.status}`);
        }
      } catch (error) {
        Swal.fire({
          title: "Sorry!",
          text: `Payment failed: ${error.message}`,
          icon: "error",
          confirmButtonColor: "#4874d7",
        });
        return error.message;
      }
    },

    getUserData: async () => {
      let store = getStore();
      let actions = getActions();
      let body = "";

      const token = localStorage.getItem("token");
      const decodedToken = jwt_decode(token);
      const userId = decodedToken.sub;

      let { responseJson, response } = await actions.useFetch(
        `/users/${userId}`,
        body,
        "GET"
      );

      if (response.ok) {
        const userDataWithoutNull = Object.fromEntries(
          Object.entries(responseJson).map(([key, value]) => [key, value ?? ""])
        );
        setStore({ ...store, userData: userDataWithoutNull });
      } else {
        console.log("fetch fallido");
      }

      return { responseJson, response };
    },

    getUserDocs: async () => {
      let store = getStore();
      let actions = getActions();
      let body = "";

      const token = localStorage.getItem("token");
      const decodedToken = jwt_decode(token);
      const userId = decodedToken.sub;

      let { responseJson, response } = await actions.useFetch(
        `/corporatetools/documents/${userId}`,
        body,
        "GET"
      );

      if (response.ok) {
        setStore({ ...store, userDocs: responseJson });
      } else {
        console.log("fetch fallido");
      }

      return { responseJson, response };
    },

    editUser: async (obj) => {
      let store = getStore();
      let actions = getActions();

      const token = localStorage.getItem("token");
      if (!token) return;

      const decodedToken = jwt_decode(token);
      const userId = decodedToken.sub;

      let { responseJson, response } = await actions.useFetch(
        `/users/${userId}`,
        obj,
        "PUT"
      );
      if (response.ok) {
        actions.getUserData();
      }

      return { responseJson, response };
    },

    editCompany: async (obj) => {
      let store = getStore();
      let actions = getActions();

      const token = localStorage.getItem("token");
      if (!token) return;

      const decodedToken = jwt_decode(token);
      const userId = decodedToken.sub;

      let { responseJson, response } = await actions.useFetch(
        `/companies/${userId}`,
        obj,
        "PUT"
      );
      if (response.ok) {
        actions.getUserData();
      }

      return { responseJson, response };
    },

    welcomeEmail: async () => {
      let store = getStore();
      let actions = getActions();

      let email = {
        to: `${store.userData.email}`,
        html_message: `<html>\n 
        <body>\n 
        <h4>Welcome, ${store.userData.first_name}</h4>\n
        <p>We are so glad you are starting a new company with us. This is just the start for your business.</p>\n
        <p>Congratulations!!</p>\n
        <img src=\"https://res.cloudinary.com/ddofsfryq/image/upload/v1700603099/hfqkdmwexwmy9hrmbqb1.png\" width=\"150px\"/>\n
        </body>\n
        </html>`,
        subject: "Welcome to HubStart"
      }

      let { responseJson, response } = await actions.useFetch(
        "/correo",
        email,
        "POST"
      );
      if (response.ok) {
        console.log("Email sent")
      }

      return { responseJson, response };
    },
  };
}
