import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
const backendUrl = process.env.BACKEND_URL;

export const EditFile = ({ getCompanyInfo, oldFilePath, onEditComplete }) => {
    const { store, actions } = useContext(Context);
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState(null);
    const [type, setType] = useState("");

    const handleClick = () => {
        setLoading(true);
    }

    const uploadDocument = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append("file", files[0]);
        formData.append("old_file_path", oldFilePath);

        try {
            const response = await fetch(`${backendUrl}/api/edit`, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formData,
            });

            if (response.ok) {
                setFiles(null)
                setType("")
                setLoading(false)
                document.getElementById("file-input").value = "";
                document.getElementById("document-type-select").value = "";
                onEditComplete();
                getCompanyInfo()
            } else {
                console.log("Error uploading file");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    return (
        <div className="jumbotron">
            <form onSubmit={uploadDocument} className="mt-3" >
                <input id="file-input" type="file" onChange={(e) => setFiles(e.target.files)} />
                <button className="btn btn-transparent" style={{
                    color: '#FD5200',
                    borderColor: '#FD5200',
                    backgroundColor: 'transparent',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }} onClick={handleClick}>
                    {loading ? (
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        'Upload'
                    )}
                </button>
            </form>
        </div>
    );
};

export default EditFile;
