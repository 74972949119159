import React, { useContext, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import facebook from "../../images/fb_l.png"
import Swal from "sweetalert2";
const stripePk = process.env.STRIPE_PK;

export const userServices2 = () => {
    const { store, actions } = useContext(Context);
    const [services, setServices] = useState();
    const [userEmail, setUserEmail] = useState(store.userData.email);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [myCompanies, setMyCompanies] = useState(store.userData.companies);

    const getServices = async () => {

        let { response, responseJson } = await actions.useFetch("/services?is_active=true")
        if (response.ok) {
            setServices(responseJson)
        }
    };

    const handlePurchase = async (service, description, price, frequency, stripe_price) => {
        let body = {
            company_id: selectedCompany,
            service: service,
            description: description,
            price: price,
            stripe_price: stripe_price,
            email: userEmail,

        }
        if (['day', 'week', 'month', 'year'].includes(frequency)) {
            body.is_subscription = true;
            body.end_date = frequency;
        }

        localStorage.setItem("lastPurchaseBody", JSON.stringify(body));

        try {
            let { response, responseJson } = await actions.useFetch("/purchases", body, "POST")
            console.log(body)
            console.log(response)
            if (response.ok) {
                localStorage.setItem("sessionId", responseJson.id);
                sessionStorage.setItem("purchaseId", responseJson.purchase_id);
                sessionStorage.setItem("companyId", responseJson.company_id);
                sessionStorage.setItem("purchaseItems", JSON.stringify([[responseJson.purchase_service, responseJson.purchase_price]]))
                const stripe = await loadStripe(stripePk);
                const { error } = await stripe.redirectToCheckout({ sessionId: responseJson.id });

                if (error) {
                    console.log(error);
                    Swal.fire({
                        title: 'Sorry!',
                        text: `Payment failed: ${error.message}`,
                        icon: 'error',
                        confirmButtonColor: '#4874d7'
                    })
                }
            } else {
                throw new Error(`API request failed: ${response.status}`);
            }
        } catch (error) {

            Swal.fire({
                title: 'Sorry!',
                text: `Payment failed: ${error.message}`,
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
            return error.message;
        }
    }

    useEffect(() => {
        setMyCompanies(store.userData.companies)
    }, [store.userData.companies]);

    useEffect(() => {
        getServices()
    }, []);

    return (
        <>
            <div
                className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5"
            >
                <div className="row">
                    <h3 className="mt-4">Add Services</h3>
                    <span className="medium">Here you can buy more services for your company.</span>
                </div>
                <div className="col-lg-4 mt-3">
                    <select id="document-type-select" className="form-select" aria-label="Default select example" onChange={(e) => setSelectedCompany(e.target.value)}>
                        <option value="">Select Your Company...</option>
                        {myCompanies && myCompanies.length > 0 && (
                            myCompanies.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))
                        )}
                    </select>
                </div>
                <div className="row my-2 g-3 g-lg-4 contact-section">
                    <div className="col">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                                tabIndex="0"
                            >
                                <div className="row my-2 g-3 g-lg-3">
                                    <div className="col">
                                        <div className="product-table p-3 px-md-4">
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 bg-white">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>Service and Description</th>
                                                            <th>Price</th>
                                                            <th>Frequency</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {services && services.length > 0 ? (
                                                            services.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <img
                                                                                src="https://mdbootstrap.com/img/new/avatars/8.jpg"
                                                                                alt=""
                                                                                style={{ height: "45px", width: "45px" }}
                                                                                className="rounded-circle"
                                                                            />
                                                                            <div className="ms-3">
                                                                                <p className="fw-bold mb-1">{item.service}</p>
                                                                                <p className="text-muted mb-0">{item.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{`$${item.price}`}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{`${item.frequency != "" ? item.frequency : 'N/A'}`}</p>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary"
                                                                            onClick={() => handlePurchase(item.service, item.description, item.price, item.frequency, item.stripe_price)}
                                                                            disabled={!selectedCompany}
                                                                        >
                                                                            Buy
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No Services to Show</td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default userServices2;