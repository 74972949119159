import React from "react";
import '../../styles/services-details.css';
// import Business from "../../img/business.jpg";

function userBusiness() {
    return (

        <div className="service-page-container">
            <div className="service-header">
                <h1>Business Licensing and Permits</h1>
            </div>
            <div className="service-content">
                <img src={Business} alt="Legal Documents" className="service-image" />
                <div className="service-description">
                    <p>
                        Launching and sustaining a business involves navigating a maze of legal requirements. Afthonia's Business Licensing and Permits services are tailored to guide your LLC through this process with ease and confidence.</p>
                    <p>
                        <strong>Our services streamline the essentials:</strong>
                        <br />
                        <br /><strong>1.-License Acquisition:</strong>Whether you're starting anew or expanding, we identify and acquire the necessary business licenses required for your specific industry and location, ensuring legal operations.

                        <br /><strong>2.-Permit Management:</strong>We meticulously manage all permits, from initial application to renewal, keeping your business compliant with local, state, and federal regulations.

                        <br /><strong>3.-Compliance Checks:</strong>Stay informed about the ever-changing legal landscape with our regular compliance checks, which help prevent any lapses that could lead to fines or disruptions in your business activities.

                        <br /><strong>4.-Zoning and Land Use:</strong>Navigate zoning laws with our expert guidance, ensuring that your business location and operations align with local land use regulations.

                        <br /><strong>5.-Health and Safety:</strong>We assist with the acquisition and maintenance of health and safety permits, crucial for businesses in food service, construction, manufacturing, and other regulated sectors.

                        <br /><strong>6.-Customized Support:</strong>Our services are not one-size-fits-all; they're customized to the unique needs of your LLC, considering your industry nuances and specific business model.

                        Afthonia’s Business Licensing and Permits services take the complexity out of legal compliance. By partnering with us, you ensure that your business is built on a solid legal foundation, freeing you to focus on growth and innovation with the peace of mind that all legalities are in order.

                    </p>
                </div>
            </div>
            <div className="call-to-action">
                <button className="btn btn-transparent" style={{ background: '#8da9ee', color: 'white' }}>Get Started</button>
            </div>
        </div>
    );
};

export default userBusiness;