import React, { Component, useState, useContext } from "react";
import { Context } from "../store/appContext";
import { useNavigate } from "react-router-dom";
import "../../styles/styles.css";
import "../../styles/nice-select.css";
import "../../styles/jstable.css";
import logo from "../../img/HubStart-Main-logo.png"
import { Link, useLocation } from "react-router-dom";

export const Sidebar = () => {
  const { store, actions } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  if (
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/signup-business" ||
    location.pathname === "/signup-owners" ||
    location.pathname === "/signup-agent" ||
    location.pathname === "/signup-ein"
  ) {
    return null;
  }

  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [utilityExpanded, setUtilityExpanded] = useState(false);

  const handleSettingsClick = () => {
    setSettingsExpanded((prevExpanded) => !prevExpanded);
  };

  const handleUtilityClick = () => {
    setUtilityExpanded((prevExpanded) => !prevExpanded);
  };

  const handleLogout = async () => {
    const { response } = await actions.logout();
    if (response.ok) {
      navigate("/login");
    }
  };

  return (
    <div id="sidebar-wrapper" className="sidebar-wrapper">
      <div className="sidebar-heading">
        <Link to="/user/backoffice">
          <img
            id="logo"
            src={logo}
            alt=""
            style={{ height: "50px", width: "225px" }}
          />
        </Link>
      </div>
      <nav className="sidebar py-2 mb-4">
        <ul className="nav flex-column" id="nav_accordion1">
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="/user/dashboard"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  home{" "}
                </span>
                Dashboard
              </span>
            </Link>
          </li>
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="/user/mycompany"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  apartment{" "}
                </span>
                My Documents
              </span>
            </Link>
          </li>
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="/user/maildocuments"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-rounded"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  mail{" "}
                </span>{" "}
                Mail
              </span>
            </Link>
          </li>
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="/user/invoices"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-rounded"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  receipt_long{" "}
                </span>{" "}
                Invoices
              </span>
            </Link>
          </li>
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="/user/purchases"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-rounded"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  shopping_cart{" "}
                </span>{" "}
                Purchases
              </span>
            </Link>
          </li>
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="/user/businessdomain"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  captive_portal{" "}
                </span>{" "}
                Domains
              </span>
            </Link>
          </li>
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="/user/backoffice"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  group{" "}
                </span>{" "}
                User Profile
              </span>
            </Link>
          </li>
        </ul>
        <div className="divider"></div>
        <ul className="nav flex-column" id="nav_accordion">
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="user/services"
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  power{" "}
                </span>
                Add Services
              </span>
            </Link>
          </li>
          <li className="nav-item has-submenu">
            <Link
              className="nav-link d-flex justify-content-between align-items-center"
              to="#"
              onClick={handleSettingsClick}
            >
              <span className="d-flex gap-1 align-items-center">
                <span
                  className="material-symbols-outlined"
                  style={{ color: "#601986" }}
                >
                  {" "}
                  settings{" "}
                </span>
                Settings
              </span>
              <span
                className="material-symbols-outlined"
                style={{ color: "#601986" }}
              >
                {" "}
                expand_more{" "}
              </span>
            </Link>
            <ul
              className={`submenu collapse ${settingsExpanded ? "show" : ""}`}
            >
              <li>
                <Link className="nav-link" to="/user/edit-profile">
                  Edit Profile
                </Link>
              </li>
              <li>
                <button className="btn nav-link" onClick={handleLogout}>
                  Log Out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
