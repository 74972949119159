import React, { useContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/companies-details.css";
import { Uploader } from "../component/uploader/uploader.jsx";
import { EditFile } from "../component/uploader/editFile.jsx";
import profile from "../../images/profile.png";
import Swal from 'sweetalert2'

export const AdminCompanyDetails = () => {
    const { store, actions } = useContext(Context);
    const params = useParams();
    let [companyInfo, setCompanyInfo] = useState({})
    let [userData, setUserData] = useState(store.userData)
    let [companyOwners, setCompanyOwners] = useState([])
    let [registeredAgent, setRegisteredAgent] = useState({})
    const [loadingIds, setLoadingIds] = useState({});
    let [einInfo, setEinInfo] = useState({})
    let [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState("");
    const [editingDocId, setEditingDocId] = useState(null);

    const handleClick = () => {
        setLoading(true);
        changeStatus()
    }

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleEditClick = (docId) => {
        setEditingDocId(editingDocId === docId ? null : docId);
    };

    const deleteDocument = async (docPath, docId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action will permanently delete the document",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it"
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteDocument(docId, docPath)
                Swal.fire({
                    title: "Success",
                    text: "Your document has been deleted",
                    icon: "success"
                });
            }
        });
    }

    const changeStatus = async () => {
        let body = {
            status: type
        }
        let { responseJson, response } = await actions.useFetch(`/companies/${params.companyid}`, body, "PUT")
        if (response.ok) {
            setLoading(false)
            getCompanyInfo()
            Swal.fire({
                title: 'Great!',
                text: 'You have successfully updated the status',
                icon: 'success',
                confirmButtonColor: '#4874d7'
            })
        } else {
            Swal.fire({
                title: 'Sorry!',
                text: 'There was an error, please try again',
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
        }
    }

    const showNumber = async (einid) => {
        let { responseJson, response } = await actions.useFetch(`/decrypt-number/${einid}`)
        if (response.ok) {
            Swal.fire({
                title: 'Great!',
                text: `This is the SSN or ITIN: ${responseJson.tax_number}`,
                icon: 'success',
                confirmButtonColor: '#4874d7'
            })
        } else {
            Swal.fire({
                title: 'Sorry!',
                text: `${responseJson.error}`,
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
        }
    }

    const getCompanyInfo = async () => {
        let { responseJson, response } = await actions.useFetch(`/companies/${params.companyid}`)
        if (response.ok) {
            setCompanyInfo(responseJson)
            setCompanyOwners(responseJson.owners)
            setRegisteredAgent(responseJson.registered_agent)
            setEinInfo(responseJson.ein_info)
            setDocuments(responseJson.documents)
        }
    }

    const handleDeleteDocument = async (docId, docPath) => {
        let obj = {
            file_path: docPath,
            file_id: docId
        }
        let { responseJson, response } = await actions.useFetch("/delete", obj, "POST")
        if (response.ok) {
            getCompanyInfo()
        } else {
            console.log("fetch fallido")
        }
    }

    const openPDF = async (filePath, id) => {
        setLoadingIds(prevState => ({ ...prevState, [id]: true }));
        const body = { filepath: filePath };

        try {
            await actions.downloadUserDocument("/download", body, "POST");
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingIds(prevState => ({ ...prevState, [id]: false }));
        }
    };

    const getFeaturesForPlan = (planType) => {
        let features = [];
        if (planType === 'MASTER') {
            // If 'ENT', include features from 'FREE' and 'SMB'
            features = [...store.pricing.find(plan => plan.plan_type === 'FREE').features,
            ...store.pricing.find(plan => plan.plan_type === 'ENT').features];
        } else if (planType === 'ENT') {
            // If 'SMB', include features from 'FREE'
            features = [...store.pricing.find(plan => plan.plan_type === 'FREE').features];
        }

        // Add the features from the current plan
        const currentPlanFeatures = store.pricing.find(plan => plan.plan_type === planType).features;
        features.push(...currentPlanFeatures);

        return features;
    };

    const selectedPlan = store.pricing.find(plan => plan.plan_type === companyInfo.plan_type);

    const features = selectedPlan ? getFeaturesForPlan(selectedPlan.plan_type) : [];

    useEffect(() => {
        getCompanyInfo()
    }, [params.companyid]);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 h-90" style={{ marginTop: '150px' }}>
            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-lg-4">
                    <div className="p-2 p-sm-4 right-side rounded">
                        <h4 style={{ color: '#5075d0' }}>Company Info</h4>
                        <div className="d-flex justify-content-between">
                            <span className="bottom-border d-block pb-4">{companyInfo.name} {companyInfo.designator}</span>
                            <Link to={`/admin/customers/${companyInfo.user_id}`}>
                                <span>Go to customer's profile</span>
                            </Link>
                        </div>
                        <div style={{ "overflowX": "auto" }}>
                            <table>
                                <tbody>
                                    <tr className="bottom-border mt-3">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined" style={{ color: "#FD5200" }}>overview</span>
                                            Status
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {companyInfo.status}
                                        </td>
                                    </tr>
                                    <tr className="bottom-border">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined" style={{ color: "#FD5200" }}>location_on</span>
                                            State
                                        </td>
                                        <td>:</td>
                                        <td>{companyInfo.formation_state}</td>
                                    </tr>
                                    <tr className="bottom-border">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined" style={{ color: "#FD5200" }}>payments</span>
                                            Paid
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {companyInfo.is_paid === true ? "Yes" : "No"}
                                        </td>
                                    </tr>
                                    <tr className="bottom-border">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined" style={{ color: "#FD5200" }} >calendar_month</span>
                                            Signup Date
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {new Date(companyInfo.registration_date).toLocaleDateString()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="mt-2">
                                <select id="document-type-select" value={type} className="form-select" aria-label="Default select example" onChange={handleTypeChange}>
                                    <option value="">Select Status...</option>
                                    <option value="filing">Pending Filing</option>
                                    <option value="ein">Pending EIN</option>
                                    <option value="annual_report">Pending Annual Report</option>
                                    <option value="hosting">Pending Hosting</option>
                                    <option value="done">Done</option>
                                </select>
                                <button className="btn btn-transparent mt-2" style={{
                                    color: '#FD5200',
                                    borderColor: '#FD5200',
                                    backgroundColor: 'transparent',
                                    borderWidth: '1px',
                                    borderStyle: 'solid'
                                }} onClick={handleClick}>
                                    {loading ? (
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        'Change Status'
                                    )}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Formation Details</h4>
                        <div className="row d-flex">
                            <div className="col-xl-6">
                                <p>Type: {companyInfo.type}</p>
                                <p>State: {companyInfo.formation_state}</p>
                                <p>Company Name: {companyInfo.name} {companyInfo.designator}</p>
                                <p>Activity: {companyInfo.activity}</p>
                            </div>
                            <div className="col-xl-6">
                                {companyInfo.type === 'LLC' ? null : (
                                    <>
                                        <p>Shares: {companyInfo.shares}</p>
                                        <p>Shares Value: {companyInfo.shares_value}</p>
                                        <p>President: {companyInfo.president}</p>
                                        <p>Secretary: {companyInfo.secretary}</p>
                                        <p>Treasurer: {companyInfo.treasurer}</p>
                                    </>
                                )}
                                <p>Address: {companyInfo.address}</p>
                                <p>Address 2: {companyInfo.address_2}</p>
                                <p>City: {companyInfo.city}</p>
                                <p>State: {companyInfo.state}</p>
                                <p>Zip: {companyInfo.zip}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-12">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Owners Information</h4>
                        <h6>Number of Owners: {companyOwners.length}</h6>
                        <div className="row">
                            {companyOwners.map((owner, index) => (
                                <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                                    <div className="border p-2">
                                        <p>Name: {owner.first_name}</p>
                                        <p>Last Name: {owner.last_name}</p>
                                        <p>Company Name: {owner.company_name}</p>
                                        <p>Address: {owner.address}</p>
                                        <p>Address 2: {owner.address_2}</p>
                                        <p>City: {owner.city}</p>
                                        <p>State: {owner.state}</p>
                                        <p>Zip: {owner.zip}</p>
                                        {companyInfo.type === 'LLC' ? (
                                            <p>% of Ownership: {owner.percentage_ownership}</p>
                                        ) : (
                                            <p>Shares: {owner.shares_owned}</p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>


            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-6">
                    <div className="document-uploader px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Document Uploader</h4>
                        <Uploader companyId={params.companyid} getCompanyInfo={getCompanyInfo} />
                    </div>
                </div>

                <div className="col-6">
                    <div className="documents-list px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Documents List</h4>
                        <ol style={{ overflowY: 'auto', maxHeight: '120px' }}>

                            {documents.map((doc, index) => (
                                <div className="d-flex" key={index}>
                                    <li key={index}>
                                        {doc.document_type} - {new Date(doc.upload_date).toLocaleDateString()}
                                    </li>
                                    <button type="button" className="btn btn-primary m-2" onClick={() => openPDF(doc.file_path, doc.id)} disabled={loadingIds[doc.id]}>
                                        {loadingIds[doc.id] ? (
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            "View"
                                        )}
                                    </button>
                                    <button className="btn btn-primary m-2" type="button" onClick={() => handleEditClick(doc.id)}>Edit</button>
                                    <button className="btn btn-primary m-2" type="button" onClick={() => deleteDocument(doc.file_path, doc.id)}>Delete</button>
                                    {editingDocId === doc.id && <EditFile getCompanyInfo={getCompanyInfo} oldFilePath={doc.file_path} onEditComplete={() => setEditingDocId(null)} />}
                                </div>
                            ))}

                            {/* <li>Document-type-A.pdf - 01/01/2023 </li>
                            <li>Document-type-B.pdf - 02/02/2023 </li>
                            <li>Document.type-C.pdf - 03/03/2023</li>
                            <li>Document.type-D.pdf - 03/03/2023</li>
                            <li>Document.type-E.pdf - 03/03/2023</li>
                            <li>Document-type-F.pdf - 01/01/2023 </li>
                            <li>Document-type-G.pdf - 02/02/2023 </li>
                            <li>Document.type-H.pdf - 03/03/2023</li>
                            <li>Document.type-I.pdf - 03/03/2023</li>
                            <li>Document.type-J.pdf - 03/03/2023</li> */}
                        </ol>
                    </div>
                </div>
            </div>

            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-lg-4">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Registered Agent</h4>
                        <p>First Name: {registeredAgent.first_name}</p>
                        <p>Last Name: {registeredAgent.last_name}</p>
                        <p>Company Name: {registeredAgent.company_name}</p>
                        <p>Phone: {registeredAgent.phone}</p>
                        <p>Email: {registeredAgent.email}</p>
                        <p>Address: {registeredAgent.address}</p>
                        <p>Address 2: {registeredAgent.address_2}</p>
                        <p>City: {registeredAgent.city}</p>
                        <p>State: {registeredAgent.state}</p>
                        <p>Zip: {registeredAgent.zip}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>EIN Information</h4>
                        <p>First Name: {einInfo.first_name}</p>
                        <p>Last Name: {einInfo.last_name}</p>
                        <p>Document Type: {einInfo.tax_type}</p>
                        <p>Document Number: <button className="btn btn-transparent" onClick={() => showNumber(einInfo.id)}>Show SSN or ITIN</button></p>
                        <p>Foreign: {einInfo.foreign ? "Yes" : "No"}</p>
                        <p>Own Application: {einInfo.own_application ? "Yes" : "No"}</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Billing Information</h4>
                        <p>Company Name: {companyInfo.name} {companyInfo.designator}</p>
                        <p>User's Name: {companyInfo.user_fullname}</p>
                        <p>Address: {companyInfo.address}. {companyInfo.state}. {companyInfo.zip}</p>
                        <p>Phone: {companyInfo.user_phone}</p>
                        <p>Email: {companyInfo.user_email}</p>
                    </div>
                </div>
            </div>
            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-lg-4">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Selected Plan</h4>
                        {selectedPlan ? (
                            <>
                                <h6>The plan selected for this company was: {selectedPlan.title}. And the plan includes:</h6>
                                <ul>
                                    {features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <p>Plan details not found.</p>
                        )}

                    </div>
                </div>
            </div>
        </div >
    );

};

export default AdminCompanyDetails;
