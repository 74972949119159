import React, { useContext, useState, useEffect } from "react";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import "../../styles/nice-select.css";
import "../../styles/styles.css";
import Swal from 'sweetalert2'

export const SearchDomains = ({ selectedCompany, getCompanyDomain }) => {
    const { store, actions } = useContext(Context);

    const [keyword, setKeyword] = useState("");
    const [domains, setDomains] = useState([])
    const [loading, setLoading] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [domainPrice, setDomainPrice] = useState("");

    const searchDomains = async () => {
        setLoading(true);
        let body = {
            keyword
        }
        let { responseJson, response } = await actions.useFetch("/searchdomains", body, "POST")
        if (response.ok) {
            setLoading(false)
            setDomains(responseJson.results)
        } else {
            console.log("fetch fallido")
        }
    }

    const handleSelectDomain = (domain) => {
        const domainName = domain.domainName;
        const domainPrice = domain.renewalPrice || domain.purchasePrice;
        setDomainName(domainName); // This will trigger a re-render
        setDomainPrice(domainPrice); // This will also trigger a re-render
        selectDomain(domainName, domainPrice); // Pass domainName and domainPrice directly
    };

    const selectDomain = async (domainName, domainPrice) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This will be set as your company domain",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, I want it!"
        }).then((result) => {
            if (result.isConfirmed) {
                addDomain(domainName, domainPrice)
                Swal.fire({
                    title: "Added",
                    text: "Your domain has been added",
                    icon: "success"
                });
            }
        });
    }
    const addDomain = async (domainName, domainPrice) => {
        let obj = {
            company_id: selectedCompany,
            domain_name: domainName,
            domain_price: domainPrice
        }
        let { responseJson, response } = await actions.useFetch("/domains", obj, "POST")
        if (response.ok) {
            getCompanyDomain(selectedCompany)
        } else {
            console.log("fetch fallido")
        }
    }

    return (
        <>
            <div className="alert alert-primary" role="alert">
                Your business doesn't have any domain assigned. Please search and select one here:
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="input-group mt-3">
                        <input type="search" id="form1" className="form-control" placeholder="Keyword" onChange={(e) => setKeyword(e.target.value)} />
                        {/* <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">.com</span>
                        </div> */}
                        <button type="button" className="btn btn-primary" disabled={loading || keyword === ''} onClick={searchDomains}>
                            {loading ? (
                                <>Searching...</>
                            ) : (
                                <>
                                    Search <i className="fas fa-search"></i>
                                </>

                            )}

                        </button>
                    </div>
                </div>
            </div>
            {domains.length > 0 ? (
                <ul className="list-group p-0">
                    {domains.map((domain, index) => (
                        <li className="list-group-item" key={index}>
                            <div className="d-flex align-items-center justify-content-between">
                                <h5 className="mb-0 col-8">{domain.domainName}</h5>
                                {domain.purchasePrice && domain.renewalPrice && domain.purchasePrice <= 20 && domain.renewalPrice <= 20 ? (
                                    <>
                                        <h6 className="mb-0 me-2">Free</h6>
                                        <button className="btn btn-primary" onClick={() => handleSelectDomain(domain)}>Select</button>
                                    </>
                                ) : domain.purchasePrice ? (
                                    <>
                                        <h6 className="mb-0 me-2">${domain.renewalPrice.toFixed(2)}</h6>
                                        <button className="btn btn-primary" onClick={() => handleSelectDomain(domain)}>Select</button>
                                    </>
                                ) : (
                                    <>
                                        <h6 className="mb-0 me-2">Not Available</h6>
                                        <button className="btn btn-primary" disabled>Select</button>
                                    </>
                                )}
                            </div>
                        </li>
                    ))}

                </ul>
            ) : (
                <p>No domains found.</p>
            )}
        </>
    );
};

export default SearchDomains;
