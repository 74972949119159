import jwt_decode from "jwt-decode";
const backendUrl = process.env.BACKEND_URL;

export const adminStore = {
  adminLogin: true,
  pendingTasks: null,
};

export function adminActions(getStore, getActions, setStore) {
  return {
    getPendingTasks: async () => {
      let store = getStore();
      let actions = getActions();

      let { responseJson, response } = await actions.useFetch(
        "/companies?status=not_done&is_paid=true"
      );

      if (response.ok) {
        setStore({ ...store, pendingTasks: responseJson });
      } else {
        console.log("fetch fallido");
      }

      return { responseJson, response };
    },

    getUnpaidUsers: async () => {
      let store = getStore();
      let actions = getActions();

      let { responseJson, response } = await actions.useFetch(
        "/companies?is_paid=false"
      );

      if (response.ok) {
        setStore({ ...store, unpaidUsers: responseJson });
      } else {
        console.log("fetch fallido");
      }

      return { responseJson, response };
    },

    adminUserCompanies: async () => {
      let store = getStore();
      let actions = getActions();

      let { responseJson, response } = await actions.useFetch(
        "/companies"
      );

      if (response.ok) {
        setStore({ ...store, adminUserCompanies: responseJson });
      } else {
        console.log("fetch fallido");
      }

      return { responseJson, response };
    },

    searchPending: async (searchTerm) => {
      let actions = getActions();
      let { responseJson, response } = await actions.useFetch(
        `/search-companies?query=${encodeURIComponent(searchTerm)}&status=not_done&is_paid=true`
      );

      if (response.ok) {
        setStore({ searchResults: responseJson });
      } else {
        console.log("Search fetch failed", response.statusText);
      }
      return { responseJson, response };
    },

    searchCompanies: async (searchTerm) => {
      let actions = getActions();
      let { responseJson, response } = await actions.useFetch(
        `/search-companies?query=${encodeURIComponent(searchTerm)}`
      );

      if (response.ok) {
        setStore({ filteredCompanies: responseJson });
      } else {
        console.log("Search fetch failed", response.statusText);
      }
      return { responseJson, response };
    },

    searchUnpaid: async (searchTerm) => {
      let store = getStore();
      let actions = getActions();

      let { responseJson, response } = await actions.useFetch(
        `/search-companies?query=${encodeURIComponent(searchTerm)}&is_paid=false`
      );

      if (response.ok) {
        setStore({ ...store, filteredUnpaidUsers: responseJson });
      } else {
        console.log("fetch fallido");
      }

      return { responseJson, response };
    },

    getUsers: async () => {
      let actions = getActions();
      let { responseJson, response } = await actions.useFetch("/users");

      if (response.ok) {
        setStore({ users: responseJson });
      } else {
        console.log("Fetch users failed", response.statusText);
      }

      return { responseJson, response };
    },

    getLeads: async () => {
      let actions = getActions();
      let { responseJson, response } = await actions.useFetch("/search-leads");

      if (response.ok) {
        setStore({ leads: responseJson });
      } else {
        console.log("Fetch leads failed", response.statusText);
      }

      return { responseJson, response };
    },

    searchLeads: async (searchTerm) => {
      let actions = getActions();
      let encodedSearchTerm = encodeURIComponent(searchTerm);
      try {
        let { responseJson, response } = await actions.useFetch(
          `/search-leads?query=${encodedSearchTerm}`
        );
        if (response.ok) {
          setStore({ leadSearchResults: responseJson });
        } else {
          console.error("Search leads fetch failed", response.statusText);
        }
        return { responseJson, response };
      } catch (error) {
        console.error("Error occurred while searching leads", error);
        return { responseJson: null, response: null };
      }
    },

    getCustomers: async () => {
      let actions = getActions();
      let { responseJson, response } = await actions.useFetch("/users");

      if (response.ok) {
        setStore({ customers: responseJson });
      } else {
        console.log("Fetch customers failed", response.statusText);
      }

      return { responseJson, response };
    },

    searchCustomers: async (searchTerm) => {
      let actions = getActions();
      let encodedSearchTerm = encodeURIComponent(searchTerm);
      try {
        let { responseJson, response } = await actions.useFetch(
          `/search-customers?query=${encodedSearchTerm}`
        );
        if (response.ok) {
          setStore({ customersSearchResults: responseJson });
        } else {
          console.error("Search customers fetch failed", response.statusText);
        }
        return { responseJson, response };
      } catch (error) {
        console.error("Error occurred while searching customers", error);
        return { responseJson: null, response: null };
      }
    },

    getService: async (serviceid) => {
      let actions = getActions();
      let { responseJson, response } = await actions.useFetch(`/services/${serviceid}`);

      if (response.ok) {
        setStore({ service: responseJson });
      } else {
        console.log("Fetch users failed", response.statusText);
      }

      return { responseJson, response };
    },
  };
}
