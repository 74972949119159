import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/companies-details.css";
import { Uploader } from "../component/uploader/uploader.jsx";
import profile from "../../images/profile.png";
import Swal from 'sweetalert2'

export const AdminUnpaidUserDetails = () => {
    const { store, actions } = useContext(Context);
    const params = useParams();
    let [companyInfo, setCompanyInfo] = useState({})

    const getCompanyInfo = async () => {
        let { responseJson, response } = await actions.useFetch(`/companies/${params.companyid}`)
        if (response.ok) {
            setCompanyInfo(responseJson)
        }
    }

    useEffect(() => {
        getCompanyInfo()
    }, [params.companyid]);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 h-90" style={{ marginTop: '150px' }}>
            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-lg-12">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Contact Details</h4>
                        <div className="row d-flex">
                            <div className="col-xl-6">
                                <p>Name: {companyInfo.user_fullname}</p>
                                <p>Email: {companyInfo.user_email}</p>
                                <p>Phone: {companyInfo.user_phone}</p>
                            </div>
                            <div className="col-xl-6">
                                <p>Company Name: {companyInfo.name} {companyInfo.designator}</p>
                                <p>Formation State: {companyInfo.formation_state}</p>
                                <p>Activity: {companyInfo.activity}</p>
                                <p>Plan: {companyInfo.plan_type}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

};

export default AdminUnpaidUserDetails;
