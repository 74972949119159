export const statesStore = {
  states: [
    {
        state: 'Alabama',
        code: 'AL',
        llc_price: 236,
        corp_price: 236,
        llc_price_id: 'price_1OUhd2J5pqyu9gz1zjYqn6Yf',
        corp_price_id: 'price_1OUhd3J5pqyu9gz1CieAMaBb'
    },
    {
        state: 'Alaska',
        code: 'AK',
        llc_price: 250,
        corp_price: 250,
        llc_price_id: 'price_1OUhd3J5pqyu9gz1SMqNGTvu',
        corp_price_id: 'price_1OUhd4J5pqyu9gz1aM553Q2E'
    },
    {
        state: 'Arizona',
        code: 'AZ',
        llc_price: 85,
        corp_price: 60,
        llc_price_id: 'price_1OUhd4J5pqyu9gz1Ysfkw404',
        corp_price_id: 'price_1OUhd5J5pqyu9gz1wwy7YAnr'
    },
    {
        state: 'Arkansas',
        code: 'AR',
        llc_price: 45,
        corp_price: 45,
        llc_price_id: 'price_1OUhd6J5pqyu9gz1I6qj7mfB',
        corp_price_id: 'price_1OUhd6J5pqyu9gz1txWpDpjP'
    },
    {
        state: 'California',
        code: 'CA',
        llc_price: 75,
        corp_price: 105,
        llc_price_id: 'price_1OUhd7J5pqyu9gz1eag9Slam',
        corp_price_id: 'price_1OUhd8J5pqyu9gz18NJCeTvl'
    },
    {
        state: 'Colorado',
        code: 'CO',
        llc_price: 50,
        corp_price: 50,
        llc_price_id: 'price_1OUhd9J5pqyu9gz13cdMMWS0',
        corp_price_id: 'price_1OUhd9J5pqyu9gz1VW49JgOk'
    },
    {
        state: 'Connecticut',
        code: 'CT',
        llc_price: 120,
        corp_price: 250,
        llc_price_id: 'price_1OUhdAJ5pqyu9gz1yYBHfNA8',
        corp_price_id: 'price_1OUhdBJ5pqyu9gz1ywim6UKf'
    },
    {
        state: 'Delaware',
        code: 'DE',
        llc_price: 119,
        corp_price: 118,
        llc_price_id: 'price_1OUhdCJ5pqyu9gz18NsQ3uex',
        corp_price_id: 'price_1OUhdCJ5pqyu9gz1WNPYk8JC'
    },
    {
        state: 'Florida',
        code: 'FL',
        llc_price: 125,
        corp_price: 70,
        llc_price_id: 'price_1OUhdDJ5pqyu9gz1oY28LoZU',
        corp_price_id: 'price_1OUhdEJ5pqyu9gz16GeiGKOr'
    },
    {
        state: 'Georgia',
        code: 'GA',
        llc_price: 100,
        corp_price: 100,
        llc_price_id: 'price_1OUhdEJ5pqyu9gz107tqYZdW',
        corp_price_id: 'price_1OUhdFJ5pqyu9gz18bmgH5hf'
    },
    {
        state: 'Hawaii',
        code: 'HI',
        llc_price: 51,
        corp_price: 51,
        llc_price_id: 'price_1OUhdGJ5pqyu9gz1x8O9fvUX',
        corp_price_id: 'price_1OUhdGJ5pqyu9gz19X8VpPR3'
    },
    {
        state: 'Idaho',
        code: 'ID',
        llc_price: 100,
        corp_price: 100,
        llc_price_id: 'price_1OUhdHJ5pqyu9gz1FB81gheW',
        corp_price_id: 'price_1OUhdHJ5pqyu9gz1d9Rb6Ab8'
    },
    {
        state: 'Illinois',
        code: 'IL',
        llc_price: 179,
        corp_price: 154,
        llc_price_id: 'price_1OUhdIJ5pqyu9gz1EAFIYsqR',
        corp_price_id: 'price_1OUhdJJ5pqyu9gz1eN8Fk574'
    },
    {
        state: 'Indiana',
        code: 'IN',
        llc_price: 98,
        corp_price: 98,
        llc_price_id: 'price_1OUhdJJ5pqyu9gz1vB6DDhiH',
        corp_price_id: 'price_1OUhdKJ5pqyu9gz1a43ZWOsQ'
    },
    {
        state: 'Iowa',
        code: 'IA',
        llc_price: 50,
        corp_price: 50,
        llc_price_id: 'price_1OUhdKJ5pqyu9gz1y1zNcl4F',
        corp_price_id: 'price_1OUhdLJ5pqyu9gz11x1qGtXk'
    },
    {
        state: 'Kansas',
        code: 'KS',
        llc_price: 166,
        corp_price: 89,
        llc_price_id: 'price_1OUhdMJ5pqyu9gz1biAdlbsw',
        corp_price_id: 'price_1OUhdMJ5pqyu9gz1lDBZ9LxS'
    },
    {
        state: 'Kentucky',
        code: 'KY',
        llc_price: 40,
        corp_price: 55,
        llc_price_id: 'price_1OUhdNJ5pqyu9gz19FJMn0aF',
        corp_price_id: 'price_1OUhdOJ5pqyu9gz1TTXu9UGi'
    },
    {
        state: 'Louisiana',
        code: 'LA',
        llc_price: 105,
        corp_price: "NOT AVAILABLE AT THIS TIME",
        llc_price_id: 'price_1OUhdOJ5pqyu9gz1w62L4ye5',
        corp_price_id: 'price_1OUhdPJ5pqyu9gz1JAiDTk9A'
    },
    {
        state: 'Maine',
        code: 'ME',
        llc_price: 178,
        corp_price: 148,
        llc_price_id: 'price_1OUhdPJ5pqyu9gz1gMJZzxKh',
        corp_price_id: 'price_1OUhdQJ5pqyu9gz1OSJrZpdI'
    },
    {
        state: 'Maryland',
        code: 'MD',
        llc_price: 197,
        corp_price: 218,
        llc_price_id: 'price_1OUhdRJ5pqyu9gz1sEwBiShZ',
        corp_price_id: 'price_1OUhdRJ5pqyu9gz1gcJMhngG'
    },
    {
        state: 'Massachusetts',
        code: 'MA',
        llc_price: 520,
        corp_price: 265,
        llc_price_id: 'price_1OUhdSJ5pqyu9gz1FPW6XHCd',
        corp_price_id: 'price_1OUhdSJ5pqyu9gz1ByOkfsgk'
    },
    {
        state: 'Michigan',
        code: 'MI',
        llc_price: 50,
        corp_price: 60,
        llc_price_id: 'price_1OUhdTJ5pqyu9gz1677wDszq',
        corp_price_id: 'price_1OUhdUJ5pqyu9gz16u3oKKlZ'
    },
    {
        state: 'Minnesota',
        code: 'MN',
        llc_price: 155,
        corp_price: 155,
        llc_price_id: 'price_1OUhdUJ5pqyu9gz1f11gK8CD',
        corp_price_id: 'price_1OUhdVJ5pqyu9gz1oyiTGvhT'
    },
    {
        state: 'Mississippi',
        code: 'MS',
        llc_price: 53,
        corp_price: 53,
        llc_price_id: 'price_1OUhdWJ5pqyu9gz17whEJx9N',
        corp_price_id: 'price_1OUhdWJ5pqyu9gz16Rp4TQvB'
    },
    {
        state: 'Missouri',
        code: 'MO',
        llc_price: 52,
        corp_price: 60,
        llc_price_id: 'price_1OUhdXJ5pqyu9gz1ThPIRtBT',
        corp_price_id: 'price_1OUhdYJ5pqyu9gz1k3x36YWf'
    },
    {
        state: 'Montana',
        code: 'MT',
        llc_price: 35,
        corp_price: 35,
        llc_price_id: 'price_1OUhdYJ5pqyu9gz1Lkguqdm4',
        corp_price_id: 'price_1OUhdZJ5pqyu9gz1WkRolWgM'
    },
    {
        state: 'Nebraska',
        code: 'NE',
        llc_price: 109,
        corp_price: 103,
        llc_price_id: 'price_1OUhdZJ5pqyu9gz1g3i7wnEq',
        corp_price_id: 'price_1OUhdaJ5pqyu9gz1ryTz1Ix2'
    },
    {
        state: 'Nevada',
        code: 'NV',
        llc_price: 425,
        corp_price: 725,
        llc_price_id: 'price_1OUhdbJ5pqyu9gz1X9gqT5ZE',
        corp_price_id: 'price_1OUhdbJ5pqyu9gz1GYiBBTva'
    },
    {
        state: 'New Hampshire',
        code: 'NH',
        llc_price: 102,
        corp_price: 165,
        llc_price_id: 'price_1OUhdcJ5pqyu9gz1FMp9yho5',
        corp_price_id: 'price_1OUhdcJ5pqyu9gz1B8NlFM9e'
    },
    {
        state: 'New Jersey',
        code: 'NJ',
        llc_price: 130,
        corp_price: 130,
        llc_price_id: 'price_1OUhddJ5pqyu9gz1qfl0BDHm',
        corp_price_id: 'price_1OUhdeJ5pqyu9gz1zPrqeBx8'
    },
    {
        state: 'New Mexico',
        code: 'NM',
        llc_price: 50,
        corp_price: 100,
        llc_price_id: 'price_1OUhdeJ5pqyu9gz1flUk02Vm',
        corp_price_id: 'price_1OUhdfJ5pqyu9gz17w0PUmAR'
    },
    {
        state: 'New York',
        code: 'NY',
        llc_price: 205,
        corp_price: 130,
        llc_price_id: 'price_1OUhdfJ5pqyu9gz1pHllcIWI',
        corp_price_id: 'price_1OUhdgJ5pqyu9gz1wH2q952A'
    },
    {
        state: 'North Carolina',
        code: 'NC',
        llc_price: 128,
        corp_price: 128,
        llc_price_id: 'price_1OUhdhJ5pqyu9gz1XvzFsAwY',
        corp_price_id: 'price_1OUhdhJ5pqyu9gz1OHRKdLGV'
    },
    {
        state: 'North Dakota',
        code: 'ND',
        llc_price: 135,
        corp_price: 100,
        llc_price_id: 'price_1OUhdiJ5pqyu9gz1YBOoB6Wz',
        corp_price_id: 'price_1OUhdjJ5pqyu9gz1xPKo6z7L'
    },
    {
        state: 'Ohio',
        code: 'OH',
        llc_price: 99,
        corp_price: 125,
        llc_price_id: 'price_1OUhdjJ5pqyu9gz1RNLgAxwG',
        corp_price_id: 'price_1OUhdkJ5pqyu9gz1ZU4cKDOA'
    },
    {
        state: 'Oklahoma',
        code: 'OK',
        llc_price: 104,
        corp_price: 52,
        llc_price_id: 'price_1OUhdlJ5pqyu9gz1JQAwWXnf',
        corp_price_id: 'price_1OUhdlJ5pqyu9gz11YapUcWr'
    },
    {
        state: 'Oregon',
        code: 'OR',
        llc_price: 100,
        corp_price: 100,
        llc_price_id: 'price_1OUhdmJ5pqyu9gz1tWpiA7Yh',
        corp_price_id: 'price_1OUhdnJ5pqyu9gz1ykU7hrXV'
    },
    {
        state: 'Pennsylvania',
        code: 'PA',
        llc_price: 131,
        corp_price: 131,
        llc_price_id: 'price_1OUhdnJ5pqyu9gz1ZYuBBu8q',
        corp_price_id: 'price_1OUhdoJ5pqyu9gz13dY84fLC'
    },
    {
        state: 'Rhode Island',
        code: 'RI',
        llc_price: 156,
        corp_price: 238,
        llc_price_id: 'price_1OUhdoJ5pqyu9gz1DGAR0EAR',
        corp_price_id: 'price_1OUhdpJ5pqyu9gz1RUsLLXTS'
    },
    {
        state: 'South Carolina',
        code: 'SC',
        llc_price: 132,
        corp_price: 325,
        llc_price_id: 'price_1OUhdqJ5pqyu9gz1xmuerYGh',
        corp_price_id: 'price_1OUhdrJ5pqyu9gz1vcsVLemK'
    },
    {
        state: 'South Dakota',
        code: 'SD',
        llc_price: 150,
        corp_price: 150,
        llc_price_id: 'price_1OUhdrJ5pqyu9gz1AhIprsBj',
        corp_price_id: 'price_1OUhdsJ5pqyu9gz1wuOZgmN0'
    },
    {
        state: 'Tennessee',
        code: 'TN',
        llc_price: 308,
        corp_price: 108,
        llc_price_id: 'price_1OUhdsJ5pqyu9gz1v7hEZSZp',
        corp_price_id: 'price_1OUhdtJ5pqyu9gz1tX1e0U2H'
    },
    {
        state: 'Texas',
        code: 'TX',
        llc_price: 300,
        corp_price: 300,
        llc_price_id: 'price_1OUhduJ5pqyu9gz1amTUiLS8',
        corp_price_id: 'price_1OUhduJ5pqyu9gz1sQlahttc'
    },
    {
        state: 'Utah',
        code: 'UT',
        llc_price: 54,
        corp_price: 54,
        llc_price_id: 'price_1OUhdvJ5pqyu9gz1ZWiQg9sx',
        corp_price_id: 'price_1OUhdvJ5pqyu9gz17VZ3NLkL'
    },
    {
        state: 'Vermont',
        code: 'VT',
        llc_price: 125,
        corp_price: 125,
        llc_price_id: 'price_1OUhdwJ5pqyu9gz1d5JNPtea',
        corp_price_id: 'price_1OUhdxJ5pqyu9gz1QA5f3STs'
    },
    {
        state: 'Virginia',
        code: 'VA',
        llc_price: 100,
        corp_price: 75,
        llc_price_id: 'price_1OUhdyJ5pqyu9gz15aySTD3H',
        corp_price_id: 'price_1OUhdyJ5pqyu9gz147lXDDX3'
    },
    {
        state: 'Washington',
        code: 'WA',
        llc_price: 200,
        corp_price: 200,
        llc_price_id: 'price_1OUhdzJ5pqyu9gz1KeFmovwF',
        corp_price_id: 'price_1OUhe0J5pqyu9gz1i6HbG6sb'
    },
    {
        state: 'Washington D.C.',
        code: 'DC',
        llc_price: 99,
        corp_price: 99,
        llc_price_id: 'price_1OUhe0J5pqyu9gz1XtDZph9h',
        corp_price_id: 'price_1OUhe1J5pqyu9gz1MX9v6uam'
    },
    {
        state: 'West Virginia',
        code: 'WV',
        llc_price: 125,
        corp_price: 135,
        llc_price_id: 'price_1OUhe1J5pqyu9gz1TWl6LeCm',
        corp_price_id: 'price_1OUhe2J5pqyu9gz132yFgZDI'
    },
    {
        state: 'Wisconsin',
        code: 'WI',
        llc_price: 130,
        corp_price: 100,
        llc_price_id: 'price_1OUhe2J5pqyu9gz18eT5aHhS',
        corp_price_id: 'price_1OUhe3J5pqyu9gz1n73C71c5'
    },
    {
        state: 'Wyoming',
        code: 'WY',
        llc_price: 102,
        corp_price: 102,
        llc_price_id: 'price_1OUhe4J5pqyu9gz1NdX3nsVu',
        corp_price_id: 'price_1OUhe4J5pqyu9gz1KePBMmjE'
    }
],

    countries: [
      { country: 'United States', code: 'US' },
      { country: 'Afghanistan', code: 'AF' },
      { country: 'Albania', code: 'AL' },
      { country: 'Algeria', code: 'DZ' },
      { country: 'Andorra', code: 'AD' },
      { country: 'Angola', code: 'AO' },
      { country: 'Antigua and Barbuda', code: 'AG' },
      { country: 'Argentina', code: 'AR' },
      { country: 'Armenia', code: 'AM' },
      { country: 'Australia', code: 'AU' },
      { country: 'Austria', code: 'AT' },
      { country: 'Azerbaijan', code: 'AZ' },
      { country: 'Bahamas', code: 'BS' },
      { country: 'Bahrain', code: 'BH' },
      { country: 'Bangladesh', code: 'BD' },
      { country: 'Barbados', code: 'BB' },
      { country: 'Belarus', code: 'BY' },
      { country: 'Belgium', code: 'BE' },
      { country: 'Belize', code: 'BZ' },
      { country: 'Benin', code: 'BJ' },
      { country: 'Bhutan', code: 'BT' },
      { country: 'Bolivia', code: 'BO' },
      { country: 'Bosnia and Herzegovina', code: 'BA' },
      { country: 'Botswana', code: 'BW' },
      { country: 'Brazil', code: 'BR' },
      { country: 'Brunei', code: 'BN' },
      { country: 'Bulgaria', code: 'BG' },
      { country: 'Burkina Faso', code: 'BF' },
      { country: 'Burundi', code: 'BI' },
      { country: 'Cambodia', code: 'KH' },
      { country: 'Cameroon', code: 'CM' },
      { country: 'Canada', code: 'CA' },
      { country: 'Cape Verde', code: 'CV' },
      { country: 'Central African Republic', code: 'CF' },
      { country: 'Chad', code: 'TD' },
      { country: 'Chile', code: 'CL' },
      { country: 'China', code: 'CN' },
      { country: 'Colombia', code: 'CO' },
      { country: 'Comoros', code: 'KM' },
      { country: 'Congo', code: 'CG' },
      { country: 'Costa Rica', code: 'CR' },
      { country: 'Croatia', code: 'HR' },
      { country: 'Cuba', code: 'CU' },
      { country: 'Cyprus', code: 'CY' },
      { country: 'Czech Republic', code: 'CZ' },
      { country: 'Denmark', code: 'DK' },
      { country: 'Djibouti', code: 'DJ' },
      { country: 'Dominica', code: 'DM' },
      { country: 'Dominican Republic', code: 'DO' },
      { country: 'East Timor', code: 'TL' },
      { country: 'Ecuador', code: 'EC' },
      { country: 'Egypt', code: 'EG' },
      { country: 'El Salvador', code: 'SV' },
      { country: 'Equatorial Guinea', code: 'GQ' },
      { country: 'Eritrea', code: 'ER' },
      { country: 'Estonia', code: 'EE' },
      { country: 'Ethiopia', code: 'ET' },
      { country: 'Fiji', code: 'FJ' },
      { country: 'Finland', code: 'FI' },
      { country: 'France', code: 'FR' },
      { country: 'Gabon', code: 'GA' },
      { country: 'Gambia', code: 'GM' },
      { country: 'Georgia', code: 'GE' },
      { country: 'Germany', code: 'DE' },
      { country: 'Ghana', code: 'GH' },
      { country: 'Greece', code: 'GR' },
      { country: 'Grenada', code: 'GD' },
      { country: 'Guatemala', code: 'GT' },
      { country: 'Guinea', code: 'GN' },
      { country: 'Guinea-Bissau', code: 'GW' },
      { country: 'Guyana', code: 'GY' },
      { country: 'Haiti', code: 'HT' },
      { country: 'Honduras', code: 'HN' },
      { country: 'Hungary', code: 'HU' },
      { country: 'Iceland', code: 'IS' },
      { country: 'India', code: 'IN' },
      { country: 'Indonesia', code: 'ID' },
      { country: 'Iran', code: 'IR' },
      { country: 'Iraq', code: 'IQ' },
      { country: 'Ireland', code: 'IE' },
      { country: 'Israel', code: 'IL' },
      { country: 'Italy', code: 'IT' },
      { country: 'Jamaica', code: 'JM' },
      { country: 'Japan', code: 'JP' },
      { country: 'Jordan', code: 'JO' },
      { country: 'Kazakhstan', code: 'KZ' },
      { country: 'Kenya', code: 'KE' },
      { country: 'Kiribati', code: 'KI' },
      { country: 'North Korea', code: 'KP' },
      { country: 'South Korea', code: 'KR' },
      { country: 'Kuwait', code: 'KW' },
      { country: 'Kyrgyzstan', code: 'KG' },
      { country: 'Laos', code: 'LA' },
      { country: 'Latvia', code: 'LV' },
      { country: 'Lebanon', code: 'LB' },
      { country: 'Lesotho', code: 'LS' },
      { country: 'Liberia', code: 'LR' },
      { country: 'Libya', code: 'LY' },
      { country: 'Liechtenstein', code: 'LI' },
      { country: 'Lithuania', code: 'LT' },
      { country: 'Luxembourg', code: 'LU' },
      { country: 'Macedonia', code: 'MK' },
      { country: 'Madagascar', code: 'MG' },
      { country: 'Malawi', code: 'MW' },
      { country: 'Malaysia', code: 'MY' },
      { country: 'Maldives', code: 'MV' },
      { country: 'Mali', code: 'ML' },
      { country: 'Malta', code: 'MT' },
      { country: 'Marshall Islands', code: 'MH' },
      { country: 'Mauritania', code: 'MR' },
      { country: 'Mauritius', code: 'MU' },
      { country: 'Mexico', code: 'MX' },
      { country: 'Micronesia', code: 'FM' },
      { country: 'Moldova', code: 'MD' },
      { country: 'Monaco', code: 'MC' },
      { country: 'Mongolia', code: 'MN' },
      { country: 'Montenegro', code: 'ME' },
      { country: 'Morocco', code: 'MA' },
      { country: 'Mozambique', code: 'MZ' },
      { country: 'Myanmar', code: 'MM' },
      { country: 'Namibia', code: 'NA' },
      { country: 'Nauru', code: 'NR' },
      { country: 'Nepal', code: 'NP' },
      { country: 'Netherlands', code: 'NL' },
      { country: 'New Zealand', code: 'NZ' },
      { country: 'Nicaragua', code: 'NI' },
      { country: 'Niger', code: 'NE' },
      { country: 'Nigeria', code: 'NG' },
      { country: 'Norway', code: 'NO' },
      { country: 'Oman', code: 'OM' },
      { country: 'Pakistan', code: 'PK' },
      { country: 'Palau', code: 'PW' },
      { country: 'Panama', code: 'PA' },
      { country: 'Papua New Guinea', code: 'PG' },
      { country: 'Paraguay', code: 'PY' },
      { country: 'Peru', code: 'PE' },
      { country: 'Philippines', code: 'PH' },
      { country: 'Poland', code: 'PL' },
      { country: 'Portugal', code: 'PT' },
      { country: 'Qatar', code: 'QA' },
      { country: 'Romania', code: 'RO' },
      { country: 'Russia', code: 'RU' },
      { country: 'Rwanda', code: 'RW' },
      { country: 'Saint Kitts and Nevis', code: 'KN' },
      { country: 'Saint Lucia', code: 'LC' },
      { country: 'Saint Vincent and the Grenadines', code: 'VC' },
      { country: 'Samoa', code: 'WS' },
      { country: 'San Marino', code: 'SM' },
      { country: 'Sao Tome and Principe', code: 'ST' },
      { country: 'Saudi Arabia', code: 'SA' },
      { country: 'Senegal', code: 'SN' },
      { country: 'Serbia and Montenegro', code: 'CS' },
      { country: 'Seychelles', code: 'SC' },
      { country: 'Sierra Leone', code: 'SL' },
      { country: 'Singapore', code: 'SG' },
      { country: 'Slovakia', code: 'SK' },
      { country: 'Slovenia', code: 'SI' },
      { country: 'Solomon Islands', code: 'SB' },
      { country: 'Somalia', code: 'SO' },
      { country: 'South Africa', code: 'ZA' },
      { country: 'Spain', code: 'ES' },
      { country: 'Sri Lanka', code: 'LK' },
      { country: 'Sudan', code: 'SD' },
      { country: 'Suriname', code: 'SR' },
      { country: 'Swaziland', code: 'SZ' },
      { country: 'Sweden', code: 'SE' },
      { country: 'Switzerland', code: 'CH' },
      { country: 'Syria', code: 'SY' },
      { country: 'Taiwan', code: 'TW' },
      { country: 'Tajikistan', code: 'TJ' },
      { country: 'Tanzania', code: 'TZ' },
      { country: 'Thailand', code: 'TH' },
      { country: 'Togo', code: 'TG' },
      { country: 'Tonga', code: 'TO' },
      { country: 'Trinidad and Tobago', code: 'TT' },
      { country: 'Tunisia', code: 'TN' },
      { country: 'Turkey', code: 'TR' },
      { country: 'Turkmenistan', code: 'TM' },
      { country: 'Tuvalu', code: 'TV' },
      { country: 'Uganda', code: 'UG' },
      { country: 'Ukraine', code: 'UA' },
      { country: 'United Arab Emirates', code: 'AE' },
      { country: 'United Kingdom', code: 'GB' },
      { country: 'Uruguay', code: 'UY' },
      { country: 'Uzbekistan', code: 'UZ' },
      { country: 'Vanuatu', code: 'VU' },
      { country: 'Vatican City', code: 'VA' },
      { country: 'Venezuela', code: 'VE' },
      { country: 'Vietnam', code: 'VN' },
      { country: 'Yemen', code: 'YE' },
      { country: 'Zambia', code: 'ZM' },
      { country: 'Zimbabwe', code: 'ZW' },
    ],
      
}

export function statesActions(getStore, getActions, setStore) {
  return 
}
