
export const pricingStore = {
    pricing: [
    {
        id:1, 
        title: 'Freelancer',
        plan_type: 'FREE',
        price: 0,
        features: ['Entity Formation Filing', 'Registered Agent*', 'Operating Agreement', 'Company Management Portal'],
        price_id: "price_1OUhrBJ5pqyu9gz1VppEmWTl"
    },
    {
        id:2, 
        title: 'Enterpreneur',
        plan_type: 'ENT',
        price: 199.95,
        features: ['ALL FREELANCER PLUS', 'EIN', 'Annual Filing', 'Beneficial Ownership Report', 'Web Hosting', 'Domain', 'Website Builder', 'Custom Email'],
        price_id: "price_1OUhrCJ5pqyu9gz1N9O80BYk"
    },
    {
        id:3, 
        title: 'Masterclass',
        plan_type: 'MASTER',
        price: 124.95,
        features: ['**ALL ENTREPRENEUR PLUS', 'Business Banking Account', 'NO FEES', '1% Cash Back on Debit', '2% Earn credit on balance', 'Virtual debit cards', 'Credit Card Processing', 'Interchange plus .5%', 'Same Day Access to Funds'],
        price_id: "price_1OUhrCJ5pqyu9gz1fkfP65Ok"
    },
    ],
}