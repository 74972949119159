import React, { useContext, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/companies-details.css";
import { Uploader } from "../component/uploader/uploader.jsx";
import profile from "../../images/profile.png";
import Swal from 'sweetalert2'

export const AdminCustomerDetails = () => {
    const { store, actions } = useContext(Context);
    const params = useParams();
    let [customerInfo, setCustomerInfo] = useState({})

    const getCustomerInfo = async () => {
        let { responseJson, response } = await actions.useFetch(`/users/${params.customerid}`)
        if (response.ok) {
            setCustomerInfo(responseJson)
        }
    }

    useEffect(() => {
        getCustomerInfo()
    }, [params.customerid]);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 h-90" style={{ marginTop: '150px' }}>
            <div className="row g-3 g-lg-4 mb-4">
                <div className="col-lg-6">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Customer Details</h4>
                        <div className="row d-flex">
                            <div className="col-xl-6">
                                <p>Name: {customerInfo.first_name} {customerInfo.last_name}</p>
                                <p>Email: {customerInfo.email}</p>
                                <p>Phone: {customerInfo.phone}</p>
                                <p>Date: {new Date(customerInfo.signup_date).toLocaleDateString()}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="company-details px-3 px-lg-4 py-4 rounded">
                        <h4 className="mb-3" style={{ color: '#5075d0' }}>Companies</h4>
                        <div className="row d-flex">
                            <div className="col-xl-6">
                                {customerInfo.companies && customerInfo.companies.length > 0 ? (
                                    <ol >
                                        {customerInfo.companies.map((company, index) => (
                                            <Link to={`/admin/companies/${company.id}`}>
                                                <li key={index}>{company.name} {company.designator}</li>
                                            </Link>
                                        ))}
                                    </ol>
                                ) : (
                                    <p>No companies found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

};

export default AdminCustomerDetails;
