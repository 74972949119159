import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
const backendUrl = process.env.BACKEND_URL;
const stripePk = process.env.STRIPE_PK;

export const newCompanyStore = {
    newCompanyForm: {
        company: {
            type:"",
            formation_state:"",
            name:"",
            designator: "",
            activity:"",
            afthonia_address:true,
            address:"",
            address_2:"",
            city:"",
            state:"",
            country:"",
            zip:"",
            shares:"",
            shares_value:"",
            president:"",
            secretary:"",
            treasurer:"",
            is_paid:false,
            user_id: ""
            },
        owners: {
          owner_type: "",
          first_name: "",
          last_name: "",
          company_name: "",
          afthonia_address: true,
          address: "",
          address_2: "",
          city: "",
          state: "",
          country: "",
          zip: "",
          shares_owned: "",
          percentage_ownership: ""
          },
        registered_agent: {
            agent_type: null,
            first_name:"",
            last_name:"",
            company_name:"",
            afthonia_address: true,
            address:"",
            address_2:"",
            city:"",
            state:"",
            country:"",
            zip:"",
            phone:"",
            email:""
            },
        ein: {
            first_name:"",
            last_name:"",
            tax_type:"ssn",
            tax_number:"",
            foreign:false,
            own_application:false
            }

  },
  newCompanyOwnersNumber: 1,
  newCompanyId: null,
  newCompanyCheckout: {
    line_items: [{price:"", quantity: 1}, {price:"", quantity: 1}]
  },
}

export function newCompanyActions(getStore, getActions, setStore) {

  return {

    updateNewCompanyInfo: (updatedCompany, userId) => {
      const store = getStore();

      let updatedCompanyCopy = { ...updatedCompany };

      if ('shares' in updatedCompanyCopy) {
        updatedCompanyCopy.shares = updatedCompanyCopy.shares !== "" ? parseInt(updatedCompanyCopy.shares) : 0;
    }
    if ('shares_value' in updatedCompanyCopy) {
        updatedCompanyCopy.shares_value = updatedCompanyCopy.shares_value !== "" ? parseFloat(updatedCompanyCopy.shares_value) : 0;
    }

      let updatedNewCompanyForm = {...store.newCompanyForm};

      updatedNewCompanyForm.company = updatedCompanyCopy;
      updatedNewCompanyForm.company.user_id = userId;
      updatedNewCompanyForm.registered_agent.state = updatedCompanyCopy.formation_state;

      setStore({...store, newCompanyForm: updatedNewCompanyForm});

      return store.newCompanyForm.company;
  },

    updateNewCheckout: (priceId) => {
      const { checkout } = getStore();
    
      const updatedLineItems = [...checkout.line_items];
      updatedLineItems[0].price = priceId;
    
      setStore(prevStore => ({
        ...prevStore,
        checkout: {
          ...prevStore.checkout,
          line_items: updatedLineItems
        }
      }));
    },  

    updateNewCheckout2: (priceId) => {
      const { checkout } = getStore();
    
      const updatedLineItems = [...checkout.line_items];
      updatedLineItems[1].price = priceId;
    
      setStore(prevStore => ({
        ...prevStore,
        checkout: {
          ...prevStore.checkout,
          line_items: updatedLineItems
        }
      }));
    },

  updateNewOwnersInfo: (owners) => {
    const store = getStore();

    let ownersCopy = owners.map(owner => {
        if ('shares_owned' in owner) {
            owner.shares_owned = owner.shares_owned !== "" ? parseInt(owner.shares_owned) : 0;
        }
        if ('percentage_ownership' in owner) {
            owner.percentage_ownership = owner.percentage_ownership !== "" ? parseInt(owner.percentage_ownership) : 0;
        }
        return owner;
    });

    let updatedNewCompanyForm = {...store.newCompanyForm};

    updatedNewCompanyForm.owners = ownersCopy;

    setStore({...store, newCompanyForm: updatedNewCompanyForm});

    return store.newCompanyForm.owners;
},

  updateNewOwnersNumber: (numOwners) => {
    const store = getStore();

    setStore({...store, ownersNumber: numOwners});

    return store.ownersNumber;
  },

  updateNewRegisteredAgentInfo: (registeredAgent) => {
    const store = getStore();

    let updatedNewCompanyForm = {...store.newCompanyForm};

    updatedNewCompanyForm.registered_agent = registeredAgent;

    setStore({...store, newCompanyForm: updatedNewCompanyForm});

    return store.newCompanyForm.registered_agent;
  },

  updateNewEinInfo: (einInfo) => {
    const store = getStore();

    let updatedNewCompanyForm = {...store.newCompanyForm};

    updatedNewCompanyForm.ein = einInfo;

    setStore({...store, newCompanyForm: updatedNewCompanyForm});

    return store.newCompanyForm.ein;
  },

    handleNewPayment: async () => {
      const store = getStore()
      const actions = getActions()
      try {

        const lineItems = store.checkout.line_items
        const email = store.userData.email
        const body = {line_items: lineItems, email: email}
        localStorage.setItem("lastPaymentBody", JSON.stringify(body));

        const response = await fetch(`${backendUrl}/api/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });

        if (response.ok) {
          const { id } = await response.json();

          localStorage.setItem("sessionId", JSON.stringify(id));

          const stripe = await loadStripe(stripePk);

          const { error } = await stripe.redirectToCheckout({ sessionId: id });

          if (error) {
            console.log(error);
            Swal.fire({
                title: 'Sorry!',
                text: `Payment failed: ${error.message}`,
                icon: 'error',
                confirmButtonColor: '#4874d7'
            })
          }
        } else {
          throw new Error(`API request failed: ${response.status}`);
        }
      } catch (error) {

        Swal.fire({
          title: 'Sorry!',
          text: `Payment failed: ${error.message}`,
          icon: 'error',
          confirmButtonColor: '#4874d7'
      })
        return error.message;
      }
    },

    addNewCompany: async () => {
      const { newCompanyForm } = getStore();
      let actions = getActions();
      let store = getStore();

      let { responseJson, response } = await actions.useFetch(
        "/newcompany",
        newCompanyForm,
        "POST"
      );
      if (response.ok) {
        let updatedNewCompanyId = {...store.newCompanyId};
        updatedNewCompanyId = responseJson.company_id;
        setStore({...store, newCompanyId: updatedNewCompanyId});

        Swal.fire({
            title: 'Great!',
            text: 'You have created a new company, please select your plan now',
            icon: 'success',
            confirmButtonColor: '#4874d7'
        })
      } else {
        Swal.fire({
          title: 'Sorry!',
          text: "Signup failed. Please try again",
          icon: 'error',
          confirmButtonColor: '#4874d7'
      })
      }

      return response;
    },
};
}