import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/home.css";

export const AdminServices2 = () => {
    const { store, actions } = useContext(Context);
    const [services, setServices] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const getServices = async () => {
        let { responseJson, response } = await actions.useFetch("/services")
        if (response.ok) {
            setServices(responseJson);
        }
    };

    const changeStatus = async (serviceId, status) => {
        let body = {
            is_active: !status
        }
        let { responseJson, response } = await actions.useFetch(`/services/${serviceId}`, body, "PUT")
        if (response.ok) {
            setServices(prevServices => prevServices.map(service => {
                if (service.id === serviceId) {
                    return { ...service, is_active: !service.is_active };
                }
                return service;
            }))
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value.trim() === '') {
            getServices();
        }
    };

    const handleSearchIconClick = () => {
        setShowSearch(!showSearch);
        if (!showSearch) {
            setTimeout(() => {
                document.querySelector('.search-input').focus();
            }, 500);
        }
    };

    const handleSearchSubmit = async () => {
        if (searchTerm.trim()) {
            await actions.searchPurchasedServices(searchTerm);
            setPurchasedServices(store.searchResults);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handleSort = (field) => {

        if (sortField === field) {
            // Toggle sort direction for other fields
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }

        // Sort the data for other fields
        sortData(field);
    };

    const sortData = (field) => {
        const sortedTasks = [...services];
        sortedTasks.sort((a, b) => {
            let valA, valB;

            switch (field) {
                case 'service':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'price':
                    valA = new Date(a.price);
                    valB = new Date(b.price);
                    break;
                case 'is_active':
                    valA = a[field] ? 1 : 0;
                    valB = b[field] ? 1 : 0;
                    break;
                default:
                    return 0;
            }

            // For numerical and predefined order sorting
            return (valA < valB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });

        setServices(sortedTasks);
    };

    useEffect(() => {
        getServices();
    }, []);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">
            <div className="row justify-content-between align-items-center mt-4">
                <div className="col-md-6">
                    <h3>Services</h3>
                    <span className="medium">List of all services.</span>
                    <div className="mt-3">
                        <Link to="/admin/newservice">
                            <button className="btn btn-primary" type="button">Add New Service</button>
                        </Link>
                    </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <input
                        className={`form-control search-input ${showSearch ? 'open' : ''}`}
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button className="btn btn-transparent" type="button" onClick={handleSearchIconClick} style={{ backgroundColor: '#5075d0', color: 'white' }}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                </div>
            </div>
            <div className="row my-2 g-3 g-lg-4 contact-section">
                <div className="col">
                    <div className="product-table p-3 px-md-4">
                        <div className="table-responsive">
                            <table className="table align-middle mb-0 bg-white">
                                <thead className="bg-light">
                                    <tr>
                                        <th style={{ cursor: 'pointer' }} onClick={() => getServices()}>#</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('service')}>Service Name</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('price')}>Price</th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => handleSort('is_active')}>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {services && services.length > 0 ? (
                                        services.map((service, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{service.service}</td>
                                                <td>{service.price}</td>
                                                <td>
                                                    <span className={`badge ${service.is_active === true ? 'bg-success' : 'bg-warning'} rounded-pill`}>
                                                        {`${service.is_active === true ? 'Active' : 'Inactive'}`}
                                                    </span>
                                                </td>
                                                <td>
                                                    <Link to={`/admin/services/${service.id}`}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-link btn-sm rounded-pill"
                                                        >
                                                            Edit
                                                        </button>
                                                    </Link>
                                                    <button
                                                        type="button"
                                                        className="btn btn-link btn-sm rounded-pill"
                                                        onClick={() => changeStatus(service.id, service.is_active)}
                                                    >
                                                        Change Status
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">No Purchased Services</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminServices2;
