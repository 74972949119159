import React, { useContext, useState, useEffect } from "react";
import { Context } from "../store/appContext";
import "../../styles/home.css";
import profile from "../../images/profile.png";
import googleImage from "../../images/google_md.png"
import facebookImage from "../../images/facebook_md.png"
import telegramImage from "../../images/telegram_md.png"

export const Backoffice = () => {
    const { store, actions } = useContext(Context);
    let [infoUser, setInfoUser] = useState(null)
    let [userCompanies, setUserCompanies] = useState([])

    useEffect(() => {
        setInfoUser(store.userData)
        setUserCompanies(store.userData.companies);
    }, [store.userData]);

    return (
        <div className="container-fluid main-content px-2 px-lg-4 mt-5">
            <div className="row g-3 g-lg-4 profile-details-content-3 pb-4">
                <div className="col-xl-6">
                    <div className="p-2 p-sm-4 right-side rounded">
                        {/* <img src={profile} alt="" /> */}
                        <h4>Profile Details</h4>
                        <span className="bottom-border d-block pb-4">{infoUser == null ? "" : `${infoUser.first_name} ${infoUser.last_name}`}</span>
                        <div style={{ "overflowX": "auto" }}>
                            <table>
                                <tbody>
                                    <tr className="bottom-border mt-3">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined text-gray">call</span>
                                            Phone Number
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {infoUser == null ? "" : infoUser.phone}
                                        </td>
                                    </tr>
                                    <tr className="bottom-border">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined text-gray">location_on</span>
                                            Location
                                        </td>
                                        <td>:</td>
                                        <td>{infoUser == null ? "" : infoUser.country}</td>
                                    </tr>
                                    <tr className="bottom-border">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined text-gray">email</span>
                                            Email
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {infoUser == null ? "" : infoUser.email}
                                        </td>
                                    </tr>
                                    <tr className="bottom-border">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined text-gray">apartment</span>
                                            Companies
                                        </td>
                                        <td>:</td>
                                        {userCompanies && userCompanies.length > 0 && (
                                            <td>
                                                {userCompanies.length}
                                            </td>
                                        )}

                                    </tr>
                                    <tr className="bottom-border">
                                        <td className="d-flex align-items-center gap-1 d-inline-block">
                                            <span className="material-symbols-outlined text-gray">calendar_month</span>
                                            Contact Since
                                        </td>
                                        <td>:</td>
                                        <td>
                                            {infoUser == null ? "" : new Date(infoUser.signup_date).toLocaleDateString()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="company-details px-3 px-lg-4 pb-4 pt-4 rounded">
                        <h4 className="mb-3">A Message For You</h4>
                        <p>
                            Congratulations on starting your business! This is a fantastic achievement, and
                            I want to send you my heartfelt wishes for a fantastic journey ahead.
                        </p>
                        <p>
                            As you embark on this exciting path, remember that every step you take brings you closer to your goals.
                            Embrace the challenges, learn from them, and celebrate your victories along the way.
                        </p>
                        <p>
                            Believe in your abilities, stay focused, and remain determined, even when faced with obstacles.
                            Your passion and hard work have brought you here, and I have no doubt that you have what it takes to
                            make your business flourish.
                        </p>
                        <p>
                            Trust in yourself, surround yourself with positivity, and never stop chasing your dreams. May this new
                            chapter be filled with endless growth, fulfillment, and incredible success. Good luck on your exciting
                            business adventure!
                        </p>
                        <div className="d-flex flex-row-reverse">
                            <p className="h5 mt-2">Rick Applewhite - Founder</p>
                        </div>
                    </div>
                    {/* <div className="right-bottom p-2 p-sm-4 mt-4">
                        <h4 className="pt-2 pb-4 mb-4 bottom-border">My Company</h4>
                        {userCompanies && userCompanies.length > 0 && (
                            userCompanies.map((item, index) => (
                                <div key={index}>
                                    <span className="white-btn mb-3 me-1 px-lg-4">{item.name} {item.designator}</span>
                                    <span className="white-btn mb-3 me-1 px-lg-4">State: {item.formation_state}</span>
                                    <span className="white-btn mb-3 me-1 px-lg-4">Date: {new Date(item.registration_date).toLocaleDateString()}</span>
                                    <span className="white-btn mb-3 me-1 px-lg-4">Status: {item.status}</span>
                                </div>
                            ))
                        )}
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Backoffice;
