import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../store/appContext";
import "../../styles/home.css";

export const AdminOrders = () => {
    const { store, actions } = useContext(Context);
    const [pendingTasks, setPendingTasks] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [amountClickCount, setAmountClickCount] = useState(0);
    const [statusClickCount, setStatusClickCount] = useState(0);
    const [typeClickCount, setTypeClickCount] = useState(0);
    const [planClickCount, setPlanClickCount] = useState(0);

    const getTasks = async () => {
        let { responseJson, response } = await actions.getPendingTasks();
        if (response.ok) {
            setPendingTasks(responseJson);
        }
    };

    const handleSearchChange = (event) => {
        let newSearchTerm = event.target.value;
        setSearchTerm(event.target.value);

        if (newSearchTerm.trim() === '') {
            getTasks()
        }
    };

    const handleSearchIconClick = () => {
        setShowSearch(!showSearch);
        if (!showSearch) {
            setTimeout(() => {
                document.querySelector('.search-input').focus();
            }, 500);
        }
    };

    const handleSearchSubmit = async () => {
        if (searchTerm.trim()) {
            await actions.searchPending(searchTerm);
            setPendingTasks(store.searchResults);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    function getStatusClass(status) {
        switch (status) {
            case 'filing':
                return 'bg-primary';
            case 'ein':
                return 'bg-warning';
            case 'annual_repport':
                return 'bg-secondary';
            case 'hosting':
                return 'bg-info';
            case 'done':
                return 'bg-success';
            default:
                return 'bg-danger';
        }
    }

    const handleSort = (field) => {
        if (field === 'amount') {
            // Cycle through the specific amounts
            setAmountClickCount((prevCount) => (prevCount + 1) % 3);
            sortAmountData();
        } else if (field === 'status') {
            // Cycle through the specific statuses
            setStatusClickCount((prevCount) => (prevCount + 1) % 5);
            sortStatusData();
        } else if (field === 'type') {
            // Cycle through the specific types
            setTypeClickCount((prevCount) => (prevCount + 1) % 3);
            sortTypeData();
        } else if (field === 'plan_type') {
            // Cycle through the specific plans
            setPlanClickCount((prevCount) => (prevCount + 1) % 3);
            sortPlanData();
        } else {
            if (sortField === field) {
                // Toggle sort direction for other fields
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            } else {
                setSortField(field);
                setSortDirection('asc');
            }

            // Sort the data for other fields
            sortData(field);
        }
    };

    const sortAmountData = () => {
        const amounts = [99, 199, 499];
        const targetAmount = amounts[amountClickCount];

        const sortedTasks = [...pendingTasks].sort((a, b) => {
            const amountA = a.sales_amount === targetAmount ? 0 : 1;
            const amountB = b.sales_amount === targetAmount ? 0 : 1;
            return amountA - amountB;
        });

        setPendingTasks(sortedTasks);
    };

    const sortStatusData = () => {
        const statuses = ['pending', 'filing', 'ein', 'annual_report', 'hosting'];
        const targetStatus = statuses[statusClickCount];

        const sortedTasks = [...pendingTasks].sort((a, b) => {
            const statusA = a.status === targetStatus ? 0 : 1;
            const statusB = b.status === targetStatus ? 0 : 1;
            return statusA - statusB;
        });

        setPendingTasks(sortedTasks);
    };

    const sortTypeData = () => {
        const types = ['LLC', 'SCorp', 'CCorp'];
        const targetType = types[typeClickCount];

        const sortedTasks = [...pendingTasks].sort((a, b) => {
            const typeA = a.type === targetType ? 0 : 1;
            const typeB = b.type === targetType ? 0 : 1;
            return typeA - typeB;
        });

        setPendingTasks(sortedTasks);
    };

    const sortPlanData = () => {
        const plans = ['FREE', 'SMB', 'ENT'];
        const targetPlan = plans[planClickCount];

        const sortedTasks = [...pendingTasks].sort((a, b) => {
            const planA = a.plan_type === targetPlan ? 0 : 1;
            const planB = b.plan_type === targetPlan ? 0 : 1;
            return planA - planB;
        });

        setPendingTasks(sortedTasks);
    };


    const sortData = (field) => {
        const sortedTasks = [...pendingTasks];
        sortedTasks.sort((a, b) => {
            let valA, valB;

            switch (field) {
                case 'name':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'formation_state':
                    valA = a[field];
                    valB = b[field];
                    return valA.localeCompare(valB) * (sortDirection === 'asc' ? 1 : -1);
                case 'registration_date':
                    valA = new Date(a.registration_date);
                    valB = new Date(b.registration_date);
                    break;
                default:
                    return 0;
            }

            // For numerical and predefined order sorting
            return (valA < valB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
        });

        setPendingTasks(sortedTasks);
    };

    useEffect(() => {
        getTasks();
    }, []);

    return (
        <>
            <div className="container-fluid main-content px-2 px-lg-4 pt-3 pt-lg-5 mt-5">
                <div className="row justify-content-between align-items-center mt-4">
                    <div className="col-md-6">
                        <h3>Orders</h3>
                        <span className="medium">This is the list of orders.</span>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <input
                            className={`form-control search-input ${showSearch ? 'open' : ''}`}
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                        />
                        <button className="btn btn-transparent" type="button" onClick={handleSearchIconClick} style={{ backgroundColor: '#5075d0', color: 'white' }}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                </div>
                <div className="row my-2 g-3 g-lg-4 contact-section">
                    <div className="col">
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                                tabIndex="0"
                            >
                                <div className="row my-2 g-3 g-lg-3">
                                    <div className="col">
                                        <div className="product-table p-3 px-md-4">
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 bg-white">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => getTasks()}>#</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('name')}>Company</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('type')}>Type</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('formation_state')}>State</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('registration_date')}>Registration Date</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('plan_type')}>Plan</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('amount')}>Amount</th>
                                                            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('status')}>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pendingTasks && pendingTasks.length > 0 ? (
                                                            pendingTasks.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    {/* <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="ms-3">
                                                                                <p className="fw-bold mb-1">{item.name}</p>
                                                                                <p className="text-muted mb-0">{item.designator}</p>
                                                                            </div>
                                                                        </div>
                                                                    </td> */}
                                                                    <td>
                                                                        <p className="fw-bold mb-1">{item.name}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{item.type}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{item.formation_state}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{item.registration_date}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">{item.plan_type}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="fw-normal mb-1">${item.sales_amount}.00</p>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge rounded-pill d-inline ${getStatusClass(item.status)}`}>
                                                                            {item.status}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/admin/companies/${item.id}`}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-link btn-sm rounded-pill"
                                                                            >
                                                                                View Details
                                                                            </button>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center">No Orders</td>
                                                            </tr>
                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminOrders;